<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ tab_name }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="22">
              <el-col :span="24">
                <el-form-item label="">
                  <el-row type="flex" :gutter="12">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="12">
                        <el-col :span="6">{{
                          $t("croppingtabs.month")
                        }}</el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Cut_Month"
                        /></el-col>
                        <el-col :span="6">{{ $t("croppingtabs.day") }}</el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Cut_Day"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="">
                  <el-row type="flex" :gutter="8">
                    <el-col
                      :span="4"
                      style="
                        padding-left: 6px;
                        padding-right: 6px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      {{ $t("cuttingtab.cutting_hours") }}
                    </el-col>
                    <el-col :span="5">
                      <el-checkbox
                        true-label="1"
                        false-label="0"
                        v-model="content_use.Cut_Grain"
                        size="mini"
                        >{{ $t("cuttingtab.grain") }}</el-checkbox
                      >
                    </el-col>
                    <el-col :span="5">
                      <el-checkbox
                        true-label="1"
                        false-label="0"
                        v-model="content_use.Cut_Leaf"
                        size="mini"
                        >{{ $t("cuttingtab.leaf") }}</el-checkbox
                      >
                    </el-col>
                    <el-col :span="5">
                      <el-checkbox
                        true-label="1"
                        false-label="0"
                        v-model="content_use.Cut_Stem"
                        size="mini"
                        >{{ $t("cuttingtab.stem") }}</el-checkbox
                      >
                    </el-col>
                    <el-col :span="5">
                      <el-checkbox
                        true-label="1"
                        false-label="0"
                        v-model="content_use.Cut_Root"
                        size="mini"
                        >{{ $t("cuttingtab.root") }}</el-checkbox
                      >
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("cuttingtab.cut_fraction") }}</el-col
                        >
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Cut_Fraction"
                            :min="0"
                            :max="1"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider></el-divider>

            <el-row
              type="flex"
              :gutter="0"
              style="justify-content: flex-end; margin-top: 1rem"
            >
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="modifyRow(content_use, current_content_list_id)"
                  :disabled="current_content_list_id === null"
                  >{{ $t("croppingtabs.confirm_update") }} {{ tab_name }}
                  {{
                    current_content_list_id !== null
                      ? "(ID=" + content_use[tab_col_id] + ")"
                      : ""
                  }}</el-button
                >
                <el-button size="mini" type="info" @click="addRow(content_use)"
                  >{{ $t("croppingtabs.template_create_new") }}
                  {{ tab_name }}</el-button
                >
              </el-button-group>
            </el-row>
            {{ content_use }}
            <el-divider></el-divider>

            <el-row type="flex" max-height="150">
              <el-table
                :data="content_ex"
                style="width: 100%"
                id="content-list"
              >
                <el-table-column prop="id" label="ID" fixed="left">
                </el-table-column>
                <el-table-column :prop="tab_col_id" :label="tab_name + ' ID'">
                </el-table-column>
                <el-table-column prop="Cut_Month" label="Month" />
                <el-table-column prop="Cut_Day" label="Day" />
                <el-table-column prop="Cut_Fraction" label="Fraction" />
                <el-table-column
                  :label="$t('buttons.operation')"
                  fixed="right"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="info"
                      @click="selectRow(scope.row.id)"
                      >{{ $t("buttons.change") }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteRow(scope.row.id)"
                      >{{ $t("buttons.delete") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import cropping_base from "@/mixins/cropping_base";
import config_template from "@/data/form_config_template";

export default {
  name: "CuttingTab",
  components: {},
  mixins: [cropping_base],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tab_name: "Cutting",
      tab_col_id: "Cut_ID",
      tab_number_key: "Cutting_Number",
      tab_content_key: "Cutting_Management",
      imported: false,
      current_content_list_id: null,
      config_list: [],
      content_template: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Cutting
            .Cutting_Management[0]
        )
      ),
      content_use: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Cutting
            .Cutting_Management[0]
        )
      ),
    };
  },
  mounted() {
    // let vm = this;
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "../block_styles";
#content-list {
  td {
    padding: 0.5rem 0;
  }
}
</style>
