<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ $t("block.data_header.upload") }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="12">
              <el-col :span="12">
                <el-form-item :label="$t('block.data_header.simulation_name')">
                  <el-input
                    v-model="config_json.Simulation_Name"
                    placeholder="Simulation Name"
                    size="mini"
                  ></el-input>
                </el-form-item>
                <el-form-item :label="$t('block.data_header.comment')">
                  <el-input
                    v-model="form.note"
                    :placeholder="$t('block.data_header.comment_palceholder')"
                    size="mini"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('block.data_header.remote_node')">
                  <el-select
                    style="width: 100%"
                    size="mini"
                    v-model="remote_node_id"
                    :placeholder="
                      remote_nodes.length === 0
                        ? $t('block.data_header.remote_node_add')
                        : $t('block.data_header.remote_node_select')
                    "
                    :disabled="false"
                    @focus="getRemoteNodes"
                  >
                    <el-option
                      v-for="item in remote_nodes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      style="margin-top: 0.2rem"
                    >
                      <span style="float: left">{{ item.name }}</span>
                      <span class="worker-status">
                        <WorkerStatusTag
                          v-if="item.id in nodes_status"
                          :workers_status="nodes_status[item.id].workers_status"
                        />
                        <el-tag v-else type="danger"></el-tag>
                      </span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.ip }}:{{ item.port }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('block.data_header.model_directory')">
                  <el-select
                    style="width: 100%"
                    size="mini"
                    v-model="remote_model"
                    :placeholder="
                      remote_models.length === 0
                        ? $t('block.data_header.remote_model_add')
                        : $t('block.data_header.remote_model_select')
                    "
                    :disabled="false"
                    @focus="getRemoteModels"
                  >
                    <el-option
                      v-for="(item, key) in remote_models"
                      :key="key"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex" :gutter="12">
              <el-col :span="12">
                <el-form-item :label="$t('block.data_header.data_source')">
                  <el-radio v-model="select_from_uploaded_data" :label="true">{{
                    $t("block.upload_and_select")
                  }}</el-radio>
                  <el-radio
                    v-model="select_from_uploaded_data"
                    :label="false"
                    >{{ $t("block.upload_new") }}</el-radio
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              class="upload-block"
              id="Upload_Data"
              v-if="select_from_uploaded_data === false"
            >
              <el-col :span="12">
                <el-upload
                  style="height: 100px"
                  ref="upload"
                  accept=".zip"
                  :headers="this.headers4reqs"
                  :action="upload_url"
                  list-type="text"
                  :auto-upload="false"
                  :limit="1"
                  :on-remove="checkRemovingFile"
                  :on-change="checkRemainingFile"
                  :on-success="handleSubmitTask"
                >
                  <span>
                    <i slot="default" class="el-icon-plus"></i>
                    {{ $t("block.add_zip") }}
                  </span>
                </el-upload>
              </el-col>
            </el-row>

            <el-row class="upload-block" id="Select_Data" v-else>
              <el-col :span="12">
                <el-select
                  style="width: 100%"
                  size="mini"
                  v-model="data_id_selected"
                  :placeholder="
                    data_source.length === 0
                      ? $t('block.data_header.select_null')
                      : $t('block.data_header.select_data')
                  "
                  :disabled="data_source.length === 0"
                >
                  <el-option
                    v-for="item in data_source"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

            <el-divider></el-divider>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col
                :span="24"
                style="display: flex; justify-content: flex-end"
              >
                <el-button
                  style="width: 150px"
                  type="info"
                  @click="handleConfigPreview"
                >
                  {{ $t("block.preview") }}
                </el-button>
                <el-button
                  style="width: 200px"
                  type="primary"
                  @click="handleUpload"
                  :disabled="
                    confirm_works === false ||
                    uploading === true ||
                    remote_model == null ||
                    remote_node_id == null
                  "
                  v-if="select_from_uploaded_data === false"
                >
                  {{ $t("block.create_and_upload") }}
                </el-button>
                <el-button
                  style="width: 200px"
                  type="primary"
                  @click="handleSubmitWithExistingData"
                  :disabled="
                    submit_task_in_processing ||
                    data_id_selected == null ||
                    remote_model == null ||
                    remote_node_id == null
                  "
                  v-else
                >
                  {{ $t("taskcenter.create_task") }}
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog
      :title="$t('block.preview')"
      :visible.sync="dialogConfigJsonVisible"
      @open="config_json_rendered = JSON.stringify(task_config, null, 2)"
      @close="config_json_rendered = ''"
    >
      <!-- 添加下载按钮 -->

      <div class="json-block">
        <pre v-html="config_json_rendered"></pre>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="download_current_config(task_config)">{{
          $t("buttons.download_current_config")
        }}</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import apiURL from "@/data/api";
import { mapGetters, mapState } from "vuex";
import WorkerStatusTag from "@/components/WorkerStatusTag";
export default {
  name: "UploadBlock",
  components: { WorkerStatusTag },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    config_json: {
      type: Object,
    },
  },
  data() {
    return {
      task_id: null,
      priorities: [
        {
          id: 0,
          name: this.$t("uploadblock.priority_high"),
        },
        {
          id: 1,
          name: this.$t("uploadblock.priority_normal"),
        },
        {
          id: 2,
          name: this.$t("uploadblock.priority_low"),
        },
      ],
      remote_node_id: null,
      remote_nodes: [],
      remote_model: null,
      remote_models: [],

      active_step: "metadataform",
      // active_step: "upload_file",
      uploading: false,
      confirm_works: false,
      task_submitted: false,

      select_from_uploaded_data: true,
      data_id_selected: null,
      data_source: [],
      submit_task_in_processing: false,

      form: {
        priority: 0,
        note: "",
        json_str: "",
      },
      // TODO: 后期去掉默认的json
      form_vod_rules: {
        priority: [
          {
            required: true,
            message: this.$t("uploadblock.priority_input"),
            trigger: "change",
          },
        ],
      },
      dialogConfigJsonVisible: false,
      config_json_rendered: "",
    };
  },
  computed: {
    ...mapState({
      nodes_status: (state) => state.nodes_status,
    }),
    ...mapGetters(["headers4reqs"]),
    upload_url() {
      return (
        process.env.VUE_APP_API_BASE_URL + apiURL.stage + apiURL.data.upload
      );
    },
    task_config() {
      return this.config_json;
    },
  },
  async mounted() {
    // 加载来自系统的数据文件信息
    this.data_source = await this.$get_api(
      apiURL.data.list,
      null,
      this.headers4reqs,
      {}
    ).then((res) => {
      console.log(this.res);
      return res;
    });
  },

  methods: {
    download_current_config(config) {
      var filename = `input_site.json`;
      var blob = new Blob([JSON.stringify(config, null, 2)], {
        type: "application/json",
      });
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display:none";
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    scrollFix(hashbang) {
      location.hash = hashbang;
    },
    scrollToRotation(i) {
      var elmnt = document.getElementById(i);
      elmnt.scrollIntoView();
    },
    handleNext(step_name) {
      this.active_step = step_name;
    },
    handlNextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t("uploadblock.params_uneffective"));
          console.log("error submit!!");
          return false;
        }
        if (this.isJson(this.form.json_str) === false) {
          this.$message.error(this.$t("uploadblock.params_not_json"));
          console.log("error submit!!");
          return false;
        }

        this.active_step = "upload_file";
        return true;
      });
    },
    async getRemoteModels() {
      console.log("fetching models....");
      this.remote_models = await this.$get_api(
        apiURL.simulation.list,
        null,
        this.headers4reqs,
        {}
      ).then((res) => {
        return res;
      });
    },
    async getRemoteNodes() {
      this.remote_nodes = await this.$get_api(
        apiURL.computation.list,
        null,
        this.headers4reqs,
        {}
      ).then((res) => {
        return res.nodes;
      });
    },
    async createTaskRemote() {
      this.form.json_str = JSON.stringify({
        Simulation_Name: this.config_json.Simulation_Name,
        Model_Config: this.task_config.Model_Config,
      });
      // this.form.note = this.config_json.Simulation_Name;
      var id = await this.$post_api(
        apiURL.task.create,
        this.form,
        null,
        this.headers4reqs
      ).then((res) => {
        this.task_id = res.id;
        return res.id;
      });
      return id;
    },
    handlGoBackTaskList() {
      this.$router.push({ name: "TaskList" });
    },
    async handleSubmitWithExistingData() {
      this.submit_task_in_processing = true;
      await this.createTaskRemote();
      if (Number.isInteger(this.data_id_selected)) {
        await this.submitTask(
          this.task_id,
          this.data_id_selected,
          this.remote_model,
          this.remote_node_id
        );
        this.submit_task_in_processing = false;
      }
    },
    async submitTask(task_id, ds_id, model_name, node_id) {
      const timer = (ms) => new Promise((res) => setTimeout(res, ms));
      var res = await this.$post_api(
        apiURL.task.submit_remote
          .replace("task_id", task_id)
          .replace("ds_id", ds_id)
          .replace("model_name", model_name)
          .replace("node_id", node_id),
        {},
        null,
        this.headers4reqs
      ).then((res) => {
        console.log(this.res);
        return res;
      });
      this.task_submitted = true;
      await timer(200);
      this.uploading = false;

      this.$router.push({ name: "TaskViewer", params: { id: task_id } });
      return res;
    },

    handlRemoveAndGoBackTaskList() {
      if (this.task_id !== null) {
        this.$delete_api(
          apiURL.task.details.replace("task_id", this.task_id),
          null,
          this.headers4reqs
        ).then((res) => {
          this.$router.push({ name: "TaskList" });
          return res;
        });
      } else {
        this.$router.push({ name: "TaskList" });
      }
    },
    async handleUpload() {
      // this.content_in_processing = true;
      this.uploading = true;
      await this.createTaskRemote();
      await this.$refs.upload.submit();
    },
    checkRemainingFile(file, filelist) {
      this.setFileSelectionStatus(filelist.length);
      return file, filelist;
    },
    checkRemovingFile(file, filelist) {
      this.setFileSelectionStatus(filelist.length);
      return file, filelist;
    },
    setFileSelectionStatus(length) {
      if (length > 0) {
        this.confirm_works = true;
      } else {
        this.confirm_works = false;
      }
    },
    async handleSubmitTask(response, file, fileList) {
      console.log(response);
      if ("id" in response) {
        let res = await this.submitTask(
          this.task_id,
          response.id,
          this.remote_model,
          this.remote_node_id
        );
        return res, response, file, fileList;
      }
    },
    handleConfigPreview() {
      this.dialogConfigJsonVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
@import "@/style/main";
@import "./block_styles";
.upload-block {
  min-height: 100px;
}

.json-block {
  height: 50vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: auto;

  pre {
    background: rgb(0, 0, 0);
    color: rgb(0, 253, 0);
    font-weight: bold;
  }
}
.worker-status {
  float: left;
  margin-left: 0.2rem;

  font-size: 0.8rem;
  // height: 1.5rem;
  display: flex;
  code {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    background: rgb(218, 218, 218);
    border-radius: 0.2rem;
  }
}
</style>
