<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ tab_name }}</p>
          <div class="form-block-body">
            <el-divider content-position="left">{{
              $t("irrigationtab.irrigation_input_mode")
            }}</el-divider>
            <el-row type="flex" :gutter="12">
              <el-col :span="12">
                <el-radio v-model="content_use.Irrigation_control" label="0">{{
                  $t("irrigationtab.irrigation_control")
                }}</el-radio>
              </el-col>
              <el-col :span="12">
                <el-row type="flex" :gutter="12">
                  <el-col :span="12" style="display: flex; align-items: center"
                    >{{ $t("irrigationtab.number_events") }}
                  </el-col>
                  <el-col :span="12">
                    <el-input-number
                      size="mini"
                      :controls="false"
                      v-model="content_use.Manure_Month"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="12" style="margin-top: 1rem">
              <el-col :span="12">
                <el-radio v-model="content_use.Irrigation_control" label="1">{{
                  $t("irrigationtab.based_on_index")
                }}</el-radio>
              </el-col>
              <el-col :span="12">
                <el-row type="flex" :gutter="12">
                  <el-col :span="12" style="display: flex; align-items: center"
                    >{{ $t("irrigationtab.irrigation_index") }}
                  </el-col>
                  <el-col :span="12">
                    <el-input-number
                      size="mini"
                      :controls="false"
                      v-model="content_use.Irrigation_Index"
                      :min="0"
                      :max="1"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-divider content-position="left">{{
              $t("irrigationtab.input_irrigation")
            }}</el-divider>
            <el-row type="flex" :gutter="22">
              <el-col :span="24">
                <el-row type="flex" :gutter="12">
                  <el-col :span="24">
                    <p>
                      {{ tab_number_key }} #:
                      {{ value[tab_number_key] }}
                    </p>
                  </el-col>
                </el-row>

                <el-form-item label="">
                  <el-row type="flex" :gutter="12">
                    <el-col :span="9">{{ $t("croppingtabs.month") }} </el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Irrigation_Month"
                      />
                    </el-col>
                    <el-col :span="3">{{ $t("croppingtabs.day") }} </el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Irrigation_Day"
                      />
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="12">
                    <el-col :span="12"
                      >{{ $t("irrigationtab.irrigation_water_amount") }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Irrigation_Water_amount"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-form-item label="">
              <el-row type="flex" :gutter="12">
                <el-col :span="4">{{ $t("irrigationtab.method") }}</el-col>
                <el-col :span="20">
                  <el-radio
                    v-model="content_use.Irrigation_Method"
                    :label="0"
                    >{{ $t("irrigationtab.furrow") }}</el-radio
                  >
                  <el-radio
                    v-model="content_use.Irrigation_Method"
                    :label="1"
                    >{{ $t("irrigationtab.sprinkler") }}</el-radio
                  >
                  <el-radio
                    v-model="content_use.Irrigation_Method"
                    :label="2"
                    >{{ $t("irrigationtab.surface_dripping") }}</el-radio
                  >
                  <el-radio
                    v-model="content_use.Irrigation_Method"
                    :label="3"
                    >{{ $t("irrigationtab.deep_dripping") }}</el-radio
                  >
                  <el-radio
                    v-model="content_use.Irrigation_Method"
                    :label="4"
                    >{{ $t("irrigationtab.film_munch") }}</el-radio
                  >
                </el-col>
              </el-row>
            </el-form-item>
            <el-row
              type="flex"
              :gutter="0"
              style="justify-content: flex-end; margin-top: 1rem"
            >
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="modifyRow(content_use, current_content_list_id)"
                  :disabled="current_content_list_id === null"
                  >{{ $t("croppingtabs.confirm_update") }} {{ tab_name }}
                  {{
                    current_content_list_id !== null
                      ? "(ID=" + content_use[tab_col_id] + ")"
                      : ""
                  }}</el-button
                >
                <el-button size="mini" type="info" @click="addRow(content_use)"
                  >{{ $t("croppingtabs.template_create_new") }}
                  {{ tab_name }}</el-button
                >
              </el-button-group>
            </el-row>
            {{ content_use }}
            <el-divider></el-divider>

            <el-row type="flex" max-height="150">
              <el-table
                :data="content_ex"
                style="width: 100%"
                id="content-list"
              >
                <el-table-column prop="id" label="ID" fixed="left">
                </el-table-column>
                <el-table-column :prop="tab_col_id" :label="tab_name + ' ID'">
                </el-table-column>
                <el-table-column
                  prop="Irrigation_Month"
                  :label="$t('croppingtabs.month')"
                >
                </el-table-column>
                <el-table-column prop="Irrigation_Day" label="Day" />
                <el-table-column
                  prop="Irrigation_Water_amount"
                  label="Water applied"
                />
                <el-table-column prop="Irrigation_Method" label="Method" />

                <el-table-column
                  :label="$t('buttons.operation')"
                  fixed="right"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="info"
                      @click="selectRow(scope.row.id)"
                      >{{ $t("buttons.change") }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteRow(scope.row.id)"
                      >{{ $t("buttons.delete") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import cropping_base from "@/mixins/cropping_base";
import config_template from "@/data/form_config_template";

export default {
  name: "IrrigationTab",
  components: {},
  mixins: [cropping_base],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tab_name: "Irrigation",
      tab_col_id: "Irrigation_ID",
      tab_number_key: "Irrigation_Number",
      tab_content_key: "Irrigation_Management",
      imported: false,
      current_content_list_id: null,
      config_list: [],
      content_template: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0]
            .Irrigation.Irrigation_Management[0]
        )
      ),
      content_use: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0]
            .Irrigation.Irrigation_Management[0]
        )
      ),
    };
  },
  mounted() {
    // let vm = this;
  },
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
@import "@/style/main";
@import "../block_styles";
#content-list {
  td {
    padding: 0.5rem 0;
  }
}
</style>
