<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ $t("block.data") }}</p>
          <div class="form-block-body">
            <el-form-item :label="$t('siteblock.sitename')">
              <el-row type="flex" :gutter="12">
                <el-col :span="24">
                  <el-input
                    label-width="200px"
                    v-model="value.Site_Name"
                    size="mini"
                  />
                </el-col>
              </el-row>
            </el-form-item>
            <el-row type="flex" :gutter="12">
              <el-col :span="12">
                <el-form-item :label="$t('siteblock.latitude')">
                  <el-input-number
                    :controls="false"
                    label-width="200px"
                    v-model="value.Latitude"
                    size="mini"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('siteblock.longitude')">
                  <el-input-number
                    :controls="false"
                    label-width="200px"
                    v-model="value.Longitude"
                    size="mini"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col :span="12">
                <el-form-item :label="$t('siteblock.simulated_year')">
                  <el-input-number
                    :controls="false"
                    label-width="200px"
                    v-model="value.Simulated_Year"
                    size="mini"
                  />
                </el-form-item>
                <el-form-item :label="$t('siteblock.satrtyear')">
                  <el-input-number
                    :controls="false"
                    label-width="200px"
                    v-model="value.Start_Year"
                    size="mini"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('siteblock.elevation')">
                  <el-input-number
                    label-width="200px"
                    v-model="value.Elevation"
                    size="mini"
                    :controls="false"
                  />
                </el-form-item>
                <el-form-item :label="$t('siteblock.dailyrecords')">
                  <el-checkbox
                    v-model="value.Daily_Record"
                    :true-label="1"
                    :false-label="0"
                    size="mini"
                    >{{ $t("siteblock.record_results") }}</el-checkbox
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col
                :span="24"
                style="display: flex; justify-content: flex-end"
              >
                <el-button @click="download_climate_file" type="primary">{{
                  $t("buttons.download_climate_file")
                }}</el-button>
                <el-button
                  style="width: 100px"
                  @click="$emit('go-next')"
                  type="primary"
                  >{{ $t("buttons.next") }}</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import apiURL from "@/data/api";
import { mapGetters } from "vuex";
export default {
  name: "SiteFormBlock",
  components: {},
  props: {
    value: {
      type: Object,
      default() {
        return {
          Site_Name: "test",
          Simulated_Year: 1,
          Start_Year: 2000,
          Latitude: 36.0,
          Longitude: 105.0,
          Daily_Record: 1,
          Elevation: 0.0,
          Continent: 2,
          Unit_system: 0,
        };
      },
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapGetters(["headers4reqs"]),
    avg_bk() {
      return (
        (this.value.Very_Labile_Litter_SOC +
          this.value.Labile_Litter_SOC +
          this.value.Litter_SOC +
          this.value.Humads_SOC +
          this.value.Humus_SOC +
          this.value.Soil_PassiveC) /
        6
      );
    },
  },
  methods: {
    checkStandalone(v) {
      if (v !== "standalone") {
        this.value.Climate_info = "";
      }
    },
    async download_climate_file() {
      const res = await this.$get_download_blob_api(
        apiURL.data.climate_data,
        null,
        this.headers4reqs,
        {
          latitude: this.value.Latitude,
          longitude: this.value.Longitude,
          start_year: this.value.Start_Year,
          time_delta: this.value.Simulated_Year,
        }
      );
      const filename = `${this.value.Latitude}-${this.value.Longitude}-${this.value.Start_Year}-${this.value.Simulated_Year}.zip`;
      var blob = new Blob([res], { type: "application/zip" });

      console.log("Blob: ", res);
      var a = document.createElement("a");

      document.body.appendChild(a);
      a.style = "display:none";
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.href = url;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      //
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "./block_styles";
</style>
