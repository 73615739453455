<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="8">
      <el-col :span="8"
        ><div class="form-block-unit">
          <p class="form-block-head">{{ $t("block.land_use") }}</p>
          <div class="form-block-body">
            <el-form-item :label="$t('soilblock.landuse_type')">
              <el-select
                v-model="value.Landuse_Type"
                placeholder=""
                size="mini"
              >
                <el-option label="Upland crop field" :value="1"></el-option>
                <el-option label="Rice paddy field" :value="2"></el-option>
                <el-option
                  label="Moist grassland/pasture"
                  :value="3"
                ></el-option>
                <el-option label="Dry grassland/pasture" :value="4"></el-option>
                <el-option label="Wetland" :value="5"></el-option>
                <el-option label="Woodland" :value="6"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('soilblock.define_soil_texture')">
              <el-radio label="1">{{
                $t("soilblock.define_soil_texture_top_soil")
              }}</el-radio>
              <el-radio label="2">{{
                $t("soilblock.define_soil_texture_hetergeneous")
              }}</el-radio>
            </el-form-item>
          </div>
        </div></el-col
      >
      <el-col :span="16"
        ><div class="form-block-unit">
          <p class="form-block-head">{{ $t("block.top_soil_texture") }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="12">
              <el-col :span="14">
                <el-form-item :label="$t('soilblock.soil_texture')">
                  <el-select
                    v-model="value.Soil_Texture"
                    placeholder=""
                    size="mini"
                  >
                    <el-option label="Sand 0.03" :value="1"></el-option>
                    <el-option label="Loamy Sand 0.06" :value="2"></el-option>
                    <el-option label="Sandy Loam 0.09" :value="3"></el-option>
                    <el-option label="Silt Loam 0.14" :value="4"></el-option>
                    <el-option label="Loam 0.19" :value="5"></el-option>
                    <el-option
                      label="Sandy clay loam 0.27"
                      :value="6"
                    ></el-option>
                    <el-option
                      label="Silty clay loam 0.34"
                      :value="7"
                    ></el-option>
                    <el-option label="Clay loam 0.40" :value="8"></el-option>
                    <el-option label="Sandy clay 0.43" :value="9"></el-option>
                    <el-option label="Silty clay 0.49" :value="10"></el-option>
                    <el-option label="Clay 0.63" :value="11"></el-option>
                    <el-option
                      label="Organic soil 0.06"
                      :value="12"
                    ></el-option>
                    <el-option
                      label="Pristine peat soil  0.06"
                      :value="13"
                    ></el-option>
                    <el-option
                      label="Cultivated/drained peat soil  0.06"
                      :value="14"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-row type="flex" :gutter="12">
                  <el-col :span="12">
                    <el-form-item :label="$t('soilblock.density')">
                      <el-input-number
                        v-model="value.Density"
                        :controls="false"
                        size="mini"
                    /></el-form-item>
                    <el-form-item :label="$t('soilblock.soil_pH')">
                      <el-input-number
                        v-model="value.Soil_pH"
                        :controls="false"
                        size="mini" /></el-form-item
                  ></el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('soilblock.field_capacity')">
                      <el-input-number
                        v-model="value.Field_capacity"
                        :controls="false"
                        size="mini"
                    /></el-form-item>
                    <el-form-item :label="$t('soilblock.wilting_point')">
                      <el-input-number
                        v-model="value.Wilting_point"
                        :controls="false"
                        size="mini"
                    /></el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="10">
                <el-form-item :label="$t('soilblock.clay_fraction')">
                  <el-input-number
                    v-model="value.Clay_fraction"
                    :controls="false"
                    :min="0"
                    :max="1"
                    size="mini"
                  />
                </el-form-item>
                <el-form-item :label="$t('soilblock.hydro_conductivity')">
                  <el-input-number
                    v-model="value.Hydro_conductivity"
                    :controls="false"
                    size="mini"
                /></el-form-item>
                <el-form-item :label="$t('soilblock.soil_porosity')">
                  <el-input-number
                    v-model="value.Soil_porosity"
                    :controls="false"
                    :min="0"
                    :max="1"
                    size="mini"
                /></el-form-item>
              </el-col>
            </el-row>
          </div></div
      ></el-col>
    </el-row>

    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">
            {{ $t("block.top_soil_structure") }}
          </p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="12">
              <el-col :span="8">
                <el-form-item
                  :label="$t('soilblock.lateral_influx_index')"
                  label-width="200px"
                >
                  <el-input-number
                    v-model="value.Lateral_influx_index"
                    :controls="false"
                    size="mini" /></el-form-item
              ></el-col>
              <el-col :span="8">
                <el-form-item :label="$t('soilblock.bypassFlow')">
                  <el-input-number
                    label-width="200px"
                    v-model="value.BypassFlow"
                    :controls="false"
                    :min="0"
                    :max="1"
                    size="mini" /></el-form-item
              ></el-col>
              <el-col :span="8">
                <el-row class="one-line-complex-form" type="flex" :gutter="6">
                  <el-col :span="16" class="label-text">
                    {{ $t("soilblock.depth_WRL_m") }}
                  </el-col>
                  <el-col :span="8">
                    <el-input-number
                      label-width="200px"
                      v-model="value.Depth_WRL_m"
                      :controls="false"
                      size="mini"
                    />
                  </el-col>
                </el-row>
                <el-row class="one-line-complex-form" type="flex" :gutter="6">
                  <el-col :span="16" class="label-text">
                    {{ $t("soilblock.highest_WT_depth") }}
                  </el-col>
                  <el-col :span="8">
                    <el-input-number
                      label-width="200px"
                      v-model="value.Highest_WT_depth"
                      :controls="false"
                      size="mini"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">
            {{ $t("block.initial_data") }}
          </p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="12" style="margin-top: 1rem">
              <el-col :span="12">
                <el-form-item :label="$t('soilblock.SOC_at_Surface')">
                  <el-input-number
                    label-width="200px"
                    v-model="value.SOC_at_Surface"
                    :controls="false"
                    size="mini"
                /></el-form-item>
                <el-divider content-position="left">{{
                  $t("soilblock.SOC_profile")
                }}</el-divider>

                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="18" class="label-text">
                    {{ $t("soilblock.SOC_profile_A") }}</el-col
                  >
                  <el-col :span="6">
                    <el-input-number
                      label-width="200px"
                      v-model="value.SOC_profile_A"
                      :controls="false"
                      size="mini"
                    />
                  </el-col>
                </el-row>
                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="18" class="label-text">
                    {{ $t("soilblock.SOC_profile_B") }}
                  </el-col>
                  <el-col :span="6">
                    <el-input-number
                      label-width="200px"
                      v-model="value.SOC_profile_B"
                      :controls="false"
                      size="mini"
                    />
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-divider content-position="left">{{
                  $t("soilblock.SOC_partitioning")
                }}</el-divider>
                <el-row type="flex" :gutter="8">
                  <el-col :span="12"> </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('soilblock.avg_bk')">
                      <el-input-number
                        label-width="200px"
                        :value="avg_bk"
                        :controls="false"
                        :disabled="true"
                        size="mini"
                    /></el-form-item>
                  </el-col>
                </el-row>

                <el-row type="flex" :gutter="6" class="label-text">
                  <el-col :span="6"> </el-col>
                  <el-col :span="3">{{
                    $t("soilblock.Very_Labile_Litter_SOC")
                  }}</el-col>
                  <el-col :span="3">{{
                    $t("soilblock.Labile_Litter_SOC")
                  }}</el-col>
                  <el-col :span="3">{{ $t("soilblock.Litter_SOC") }}</el-col>
                  <el-col :span="3">{{ $t("soilblock.Humads_SOC") }}</el-col>
                  <el-col :span="3">{{ $t("soilblock.Humus_SOC") }}</el-col>
                  <el-col :span="3">{{ $t("soilblock.Soil_PassiveC") }}</el-col>
                </el-row>
                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="6" class="label-text">
                    {{ $t("soilblock.fraction") }}</el-col
                  >
                  <el-col :span="3">
                    <!-- V.L.litter -->
                    <el-input-number
                      v-model="value.Very_Labile_Litter_SOC"
                      :controls="false"
                      size="mini"
                    />
                  </el-col>
                  <el-col :span="3">
                    <!-- L.L.litter -->
                    <el-input-number
                      label-width="200px"
                      v-model="value.Labile_Litter_SOC"
                      :controls="false"
                      size="mini"
                  /></el-col>
                  <el-col :span="3">
                    <el-input-number
                      label-width="200px"
                      v-model="value.Litter_SOC"
                      :controls="false"
                      size="mini"
                  /></el-col>
                  <el-col :span="3">
                    <el-input-number
                      label-width="200px"
                      v-model="value.Humads_SOC"
                      :controls="false"
                      size="mini"
                  /></el-col>
                  <el-col :span="3">
                    <el-input-number
                      label-width="200px"
                      v-model="value.Humus_SOC"
                      :controls="false"
                      size="mini"
                  /></el-col>
                  <el-col :span="3">
                    <el-input-number
                      label-width="200px"
                      v-model="value.Soil_PassiveC"
                      :controls="false"
                      size="mini"
                  /></el-col>
                </el-row>
                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="6" class="label-text">
                    {{ $t("soilblock.C/N") }}</el-col
                  >
                  <el-col :span="3">
                    <el-input-number
                      label-width="200px"
                      v-model="value.Very_Labile_Litter_CN"
                      :controls="false"
                      size="mini"
                  /></el-col>
                  <el-col :span="3">
                    <el-input-number
                      label-width="200px"
                      v-model="value.Labile_Litter_CN"
                      :controls="false"
                      size="mini"
                  /></el-col>
                  <el-col :span="3">
                    <el-input-number
                      v-model="value.Resistant_Litter_CN"
                      :controls="false"
                      size="mini"
                  /></el-col>
                  <el-col :span="3">
                    <el-input-number
                      v-model="value.Humad_CN"
                      :controls="false"
                      size="mini"
                  /></el-col>
                  <el-col :span="3">
                    <el-input-number
                      v-model="value.Humus_CN"
                      :controls="false"
                      size="mini"
                  /></el-col>
                  <el-col :span="3">
                    <el-input-number
                      v-model="value.Soil_PassiveCN"
                      :controls="false"
                      size="mini"
                  /></el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-divider></el-divider>
            <span class="el-form-item__label">
              {{ $t("soilblock.modify_decomposition_rates") }}
            </span>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col :span="8">
                <el-form-item :label="$t('soilblock.DC_litter_factor')">
                  <el-input-number
                    label-width="200px"
                    v-model="value.DC_litter_factor"
                    :controls="false"
                    size="mini"
                /></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('soilblock.DC_humads_factor')">
                  <el-input-number
                    label-width="200px"
                    v-model="value.DC_humads_factor"
                    :controls="false"
                    size="mini"
                /></el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('soilblock.DC_humus_factor')">
                  <el-input-number
                    label-width="200px"
                    v-model="value.DC_humus_factor"
                    :controls="false"
                    size="mini"
                /></el-form-item>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col :span="21" class="label-text">
                {{ $t("soilblock.soil_NO3") }}
              </el-col>
              <el-col :span="3">
                <el-input-number
                  label-width="200px"
                  v-model="value.Soil_NO3"
                  :controls="false"
                  size="mini"
                />
              </el-col>
            </el-row>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col :span="21" class="label-text">
                {{ $t("soilblock.soil_NH4") }}
              </el-col>
              <el-col :span="3">
                <el-input-number
                  label-width="200px"
                  v-model="value.Soil_NH4"
                  :controls="false"
                  :min="0"
                  :max="1"
                  size="mini"
                />
              </el-col>
            </el-row>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col class="label-text" :span="21" style="">
                {{ $t("soilblock.soil_microbial_index") }}
              </el-col>
              <el-col :span="3">
                <el-input-number
                  v-model="value.Soil_microbial_index"
                  :controls="false"
                  :min="0"
                  :max="1"
                  size="mini"
                />
              </el-col>
            </el-row>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col :span="21" class="label-text">
                {{ $t("soilblock.slope") }}
              </el-col>
              <el-col :span="3">
                <el-input-number
                  label-width="200px"
                  v-model="value.Slope"
                  :controls="false"
                  :min="0"
                  :max="90"
                  size="mini"
                />
              </el-col>
            </el-row>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col :span="21" class="label-text">
                {{ $t("soilblock.salinity") }}
              </el-col>
              <el-col :span="3">
                <el-input-number
                  label-width="200px"
                  v-model="value.Salinity"
                  :controls="false"
                  :min="0"
                  :max="100"
                  size="mini"
                />
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col
                :span="24"
                style="display: flex; justify-content: flex-end"
              >
                <el-button
                  style="width: 100px"
                  @click="$emit('go-next')"
                  type="primary"
                  >{{ $t("buttons.next") }}</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import apiURL from "@/data/api";
import { mapGetters } from "vuex";

export default {
  name: "SoilFormBlock",
  components: {},
  props: {
    tab_activated: {
      type: Boolean,
      default() {
        return false;
      },
    },
    site_info: {
      type: Object,
      default() {
        return {
          Site_Name: "test",
          Simulated_Year: 9,
          Start_Year: 2000,
          Latitude: 36.0,
          Longitude: 105.0,
          Daily_Record: 1,
          Elevation: 0.0,
          Continent: 2,
          Unit_system: 0,
        };
      },
    },
    value: {
      type: Object,
      default() {
        return {
          Soil_Texture: null,
          Landuse_Type: 1,
          Density: 1.3,
          Soil_pH: 7.0,
          SOC_at_Surface: 0.005,
          Clay_fraction: 0.34,
          BypassFlow: 0,
          Litter_SOC: 0.1,
          Humads_SOC: 0.1,
          Humus_SOC: 0.8,
          Soil_NO3: 0.5,
          Soil_NH4: 0.05,
          Moisture: 0,
          Lateral_influx_index: 0,
          Field_capacity: 0.55,
          Wilting_point: 0.26,
          Hydro_conductivity: 0.015,
          Soil_porosity: 0.477,
          SOC_profile_A: 0.2,
          SOC_profile_B: 0.2,
          DC_litter_factor: 1,
          DC_humads_factor: 1,
          DC_humus_factor: 1,
          Humad_CN: 10,
          Humus_CN: 10,
          Soil_PassiveC: 0,
          Soil_PassiveCN: 100,
          Soil_microbial_index: 1,
          Highest_WT_depth: 9.99,
          Depth_WRL_m: 9.99,
          Slope: 0.0,
          UseION: 0,
          Salinity: 0.0,
          Profile_type: 0,
          scs_curve_use: 0,

          Very_Labile_Litter_SOC: 0,

          Labile_Litter_SOC: 0,

          Very_Labile_Litter_CN: 5,
          Labile_Litter_CN: 25,
          Resistant_Litter_CN: 100,
        };
      },
    },
  },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    async tab_activated(nv) {
      if (nv === true) {
        console.log("soil被激活");

        const res = await this.$get_api(
          apiURL.data.soil_default_params,
          null,
          this.headers4reqs,
          {
            latitude: this.site_info.Latitude,
            longitude: this.site_info.Longitude,
          }
        );
        // 设定默认参数
        this.value.Clay_fraction = res["clay_l1"];
        this.value.SOC_at_Surface = res["SOC_l1"];
        this.value.Soil_pH = res["PH_l1"];
        this.value.Density = res["bulk_l1"];
        this.value.Field_capacity = res["FC_WFPS"];
        this.value.Soil_porosity = res["theta_s_l1"];
        this.value.Wilting_point = res["WLT_WFPS"];
        this.value.Hydro_conductivity = res["k_s_l1"];
        console.log("默认土壤参数获取完成: ", JSON.stringify(res));
      }
    },
  },
  computed: {
    ...mapGetters(["headers4reqs"]),
    avg_bk() {
      return (
        (this.value.Very_Labile_Litter_SOC +
          this.value.Labile_Litter_SOC +
          this.value.Litter_SOC +
          this.value.Humads_SOC +
          this.value.Humus_SOC +
          this.value.Soil_PassiveC) /
        6
      );
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "./block_styles";
</style>
