<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ tab_name }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="22">
              <el-col :span="24">
                <el-form-item label="">
                  <el-row type="flex" :gutter="12">
                    <el-col :span="12">
                      <el-row type="flex" :gutter="8">
                        <el-col :span="5">{{ $t("grazingtab.start") }}</el-col>
                        <el-col :span="4">{{
                          $t("croppingtabs.month")
                        }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Graz_Start_Month"
                        /></el-col>
                        <el-col :span="4">{{ $t("croppingtabs.day") }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Graz_Start_Day"
                        /></el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="12">
                      <el-row type="flex" :gutter="8">
                        <el-col :span="5">{{ $t("grazingtab.end") }}</el-col>
                        <el-col :span="4">{{
                          $t("croppingtabs.month")
                        }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Graz_End_Month"
                        /></el-col>
                        <el-col :span="4">{{ $t("croppingtabs.day") }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Graz_End_Day"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="">
                  <el-row type="flex" :gutter="8">
                    <el-col
                      :span="12"
                      style="
                        padding-left: 6px;
                        padding-right: 6px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      {{ $t("grazingtab.grazing_hours") }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        v-model="content_use.Graz_Hours_Per_day"
                        :controls="false"
                        size="mini"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="4"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("grazingtab.dairy") }}</el-col
                        >
                        <el-col
                          :span="4"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("grazingtab.beef/yak") }}</el-col
                        >
                        <el-col
                          :span="4"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("grazingtab.sheep") }}</el-col
                        >
                        <el-col
                          :span="4"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("grazingtab.horse") }}</el-col
                        >
                        <el-col
                          :span="4"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("grazingtab.pig") }}</el-col
                        >
                      </el-row>
                      <el-row type="flex" :gutter="8">
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Dairy_Head_per_ha"
                        /></el-col>

                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Beef_Head_per_ha"
                        /></el-col>
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Sheep_Head_per_ha"
                        /></el-col>
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Horse_Head_per_ha"
                        /></el-col>
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Pig_Head_per_ha"
                        /></el-col>
                      </el-row>
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("grazingtab.additional_feed") }}</el-col
                        >
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Additional_feed"
                        /></el-col>
                        <el-col
                          :span="4"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("grazingtab.feed_C/N") }}</el-col
                        >
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.AddFeed_CN"
                        /></el-col>
                      </el-row>
                      <el-row type="flex">
                        <el-col :span="24">
                          <el-select
                            v-model="content_use.ExcretaFlag"
                            :placeholder="$t('buttons.select')"
                            size="mini"
                          >
                            <el-option
                              size="mini"
                              :label="$t('grazingtab.deposit_in_field')"
                              :value="1"
                            />
                            <el-option
                              size="mini"
                              :label="$t('grazingtab.removed_from_field')"
                              :value="2"
                            />
                          </el-select>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider></el-divider>

            <el-row
              type="flex"
              :gutter="0"
              style="justify-content: flex-end; margin-top: 1rem"
            >
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="modifyRow(content_use, current_content_list_id)"
                  :disabled="current_content_list_id === null"
                  >{{ $t("croppingtabs.confirm_update") }} {{ tab_name }}
                  {{
                    current_content_list_id !== null
                      ? "(ID=" + content_use[tab_col_id] + ")"
                      : ""
                  }}</el-button
                >
                <el-button size="mini" type="info" @click="addRow(content_use)"
                  >{{ $t("croppingtabs.template_create_new") }}
                  {{ tab_name }}</el-button
                >
              </el-button-group>
            </el-row>
            {{ content_use }}
            <el-divider></el-divider>

            <el-row type="flex" max-height="150">
              <el-table
                :data="content_ex"
                style="width: 100%"
                id="content-list"
              >
                <el-table-column prop="id" label="ID" fixed="left">
                </el-table-column>
                <el-table-column :prop="tab_col_id" :label="tab_name + ' ID'">
                </el-table-column>
                <el-table-column
                  prop="Graz_Start_Month"
                  label="Start M"
                ></el-table-column>
                <el-table-column
                  prop="Graz_Start_Day"
                  label="Start D"
                ></el-table-column>
                <el-table-column
                  prop="Graz_End_Month"
                  label="End M"
                ></el-table-column>
                <el-table-column
                  prop="Graz_End_Day"
                  label="End D"
                ></el-table-column>
                <el-table-column
                  prop="Dairy_Head_per_ha"
                  label="Dairy"
                ></el-table-column>
                <el-table-column
                  prop="Beef_Head_per_ha"
                  label="Beef"
                ></el-table-column>
                <el-table-column prop="Pig_Head_per_ha" label="Pig" />
                <el-table-column prop="Sheep_Head_per_ha" label="Sheep" />
                <el-table-column prop="Horse_Head_per_ha" label="Horse" />
                <el-table-column prop="Graz_Hours_Per_day" label="Hours" />
                <el-table-column
                  :label="$t('buttons.operation')"
                  fixed="right"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="info"
                      @click="selectRow(scope.row.id)"
                      >{{ $t("buttons.change") }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteRow(scope.row.id)"
                      >{{ $t("buttons.delete") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import cropping_base from "@/mixins/cropping_base";
import config_template from "@/data/form_config_template";

export default {
  name: "GrazingTab",
  components: {},
  mixins: [cropping_base],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tab_name: "Grazing",
      tab_col_id: "Grazing_ID",
      tab_number_key: "Grazing_Number",
      tab_content_key: "Grazing_Management",
      imported: false,
      current_content_list_id: null,
      config_list: [],
      content_template: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Grazing
            .Grazing_Management[0]
        )
      ),
      content_use: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Grazing
            .Grazing_Management[0]
        )
      ),
    };
  },
  mounted() {
    // let vm = this;
  },
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
@import "@/style/main";
@import "../block_styles";
#content-list {
  td {
    padding: 0.5rem 0;
  }
}
</style>
