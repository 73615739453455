<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ $t("block.climate") }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="22" style="margin-top: 1rem">
              <el-col :span="12">
                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="18" class="label-text">
                    {{ $t("climateblock.N_concentration") }}
                  </el-col>
                  <el-col :span="6">
                    <el-input-number
                      label-width="200px"
                      v-model="value.NO3NH4_in_Rainfall"
                      :controls="false"
                      size="mini"
                    />
                  </el-col>
                </el-row>
                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="18" class="label-text">
                    {{ $t("climateblock.NH3_of_Atmosphere") }}
                  </el-col>
                  <el-col :span="6">
                    <el-input-number
                      label-width="200px"
                      v-model="value.NH3_of_Atmosphere"
                      :controls="false"
                      size="mini"
                    />
                  </el-col>
                </el-row>
                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="18" class="label-text">
                    {{ $t("climateblock.BaseCO2_of_Atmosphere") }}
                  </el-col>
                  <el-col :span="6">
                    <el-input-number
                      label-width="200px"
                      v-model="value.BaseCO2_of_Atmosphere"
                      :controls="false"
                      size="mini"
                    />
                  </el-col>
                </el-row>
                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="18" class="label-text">
                    {{ $t("climateblock.CO2_increase_rate") }}
                  </el-col>
                  <el-col :span="6">
                    <el-input-number
                      label-width="200px"
                      v-model="value.CO2_increase_rate"
                      :controls="false"
                      size="mini"
                    />
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <!-- <el-divider content-position="left"></el-divider> -->
                <el-form-item :label="$t('climateblock.climate_data_type')">
                  <el-row type="flex" :gutter="8">
                    <el-radio
                      label="0"
                      size="mini"
                      v-model="value.Climate_Data_Type"
                      >aveT, prec</el-radio
                    >
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-radio
                      label="1"
                      size="mini"
                      v-model="value.Climate_Data_Type"
                      >maxT, minT, prec</el-radio
                    >
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-radio
                      label="2"
                      size="mini"
                      v-model="value.Climate_Data_Type"
                      >maxT, minT, prec, radiation</el-radio
                    >
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-radio
                      label="3"
                      size="mini"
                      v-model="value.Climate_Data_Type"
                      >maxT, minT, prec, wind speed (m/s)</el-radio
                    >
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-radio
                      label="4"
                      size="mini"
                      v-model="value.Climate_Data_Type"
                      >radiation, maxT, minT, ave_T, Rainfall</el-radio
                    >
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-radio
                      label="5"
                      size="mini"
                      v-model="value.Climate_Data_Type"
                      >maxT, minT, prec, wind speed, radiation, relative
                      humidity</el-radio
                    >
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-radio
                      label="6"
                      size="mini"
                      v-model="value.Climate_Data_Type"
                      >maxT, minT, prec, wind speed, relative humidity</el-radio
                    >
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-radio
                      label="7"
                      size="mini"
                      v-model="value.Climate_Data_Type"
                      >maxT, minT, prec, relative humidity</el-radio
                    >
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ $t("block.data") }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="12">
              <el-col :span="8">
                <el-form-item :label="$t('climateblock.climate_name')">
                  <el-input
                    label-width="200px"
                    v-model="value.Climate_Name"
                    size="mini"
                  />
                </el-form-item>
                <!-- <el-form-item label="Data path">
                  <el-input
                    label-width="200px"
                    v-model="value.Data_Path"
                    size="mini"
                  />
                </el-form-item> -->
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('climateblock.climate_data_format')">
                  <el-select
                    v-model="value.Climate_Data_Format"
                    placeholder=""
                    size="mini"
                    @change="checkStandalone"
                  >
                    <el-option label="txt" :value="'txt'"></el-option>
                    <el-option label="excel" :value="'Excel'"></el-option>
                    <el-option label="h5" :value="'h5'"></el-option>
                    <el-option label="JSON" :value="'json'"></el-option>
                    <el-option
                      label="standalone"
                      :value="'standalone'"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="">
                  <el-checkbox
                    v-model="value.Climate_file_mode"
                    :true-label="1"
                    :false-label="0"
                    size="mini"
                    >{{ $t("climateblock.climate_file_mode") }}</el-checkbox
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-form-item
                :label="$t('climateblock.climate_data_standalone')"
                style="width: 100%"
              >
                <el-input
                  type="textarea"
                  :rows="2"
                  :placeholder="
                    value.Climate_Data_Format === 'standalone'
                      ? ''
                      : 'not standalone, unavailable'
                  "
                  v-model="value.Climate_info"
                  :disabled="value.Climate_Data_Format !== 'standalone'"
                />
              </el-form-item>
            </el-row>
            <el-divider></el-divider>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col
                :span="24"
                style="display: flex; justify-content: flex-end"
              >
                <el-button
                  style="width: 100px"
                  @click="$emit('go-next')"
                  type="primary"
                  >{{ $t("buttons.next") }}</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ClimateFormBlock",
  components: {},
  props: {
    value: {
      type: Object,
      default() {
        return {
          Climate_info: "",
          NO3NH4_in_Rainfall: 0.0,
          NH3_of_Atmosphere: 0.06,
          BaseCO2_of_Atmosphere: 380.0,
          Climate_file_mode: 0,
          CO2_increase_rate: 1.0,
          Climate_Data_Type: "1",
          Climate_Data_Format: "txt",
          Climate_Name: "Yuzhong",
          Data_Path: "./example/YuZhong",
        };
      },
    },
    site_info: {
      type: Object,
      default() {
        return {
          Site_Name: "test",
          Simulated_Year: 9,
          Start_Year: 2000,
          Latitude: 36.0,
          Longitude: 105.0,
          Daily_Record: 1,
          Elevation: 0.0,
          Continent: 2,
          Unit_system: 0,
        };
      },
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapGetters(["headers4reqs"]),
  },
  async mounted() {
    // 获取默认的climate data
  },
  methods: {
    checkStandalone(v) {
      if (v !== "standalone") {
        this.value.Climate_info = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "./block_styles";
</style>
