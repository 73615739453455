<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ tab_name }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="22">
              <el-col :span="9">
                <el-row type="flex" :gutter="12">
                  <el-col :span="24">
                    <p>
                      {{ $t("croptab.num_new_crops") }}
                      {{ value[tab_number_key] }}
                    </p>
                  </el-col>
                </el-row>
                <el-form-item :label="$t('croptab.crop_type')">
                  <el-select
                    v-model="content_use.Crop_Type"
                    :placeholder="$t('buttons.select')"
                    size="mini"
                  >
                    <el-option
                      v-for="item in crop_info"
                      size="mini"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-checkbox
                    v-model="content_use.Perennial_crop"
                    true-label="1"
                    false-label="0"
                    size="mini"
                    >{{ $t("croptab.perennial_crop") }}</el-checkbox
                  >
                </el-form-item>
                <el-form-item label="">
                  <el-checkbox
                    v-model="content_use.Cover_crop"
                    :true-label="1"
                    :false-label="0"
                    size="mini"
                    >{{ $t("croptab.cover_crop") }}</el-checkbox
                  >
                </el-form-item>
                <el-form-item label="">
                  <el-row type="flex" :gutter="12">
                    <el-col :span="9">{{ $t("croptab.plant_month") }} </el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Plant_Month"
                      />
                    </el-col>
                    <el-col :span="3">{{ $t("croptab.plant_day") }}</el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Plant_Day"
                      />
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="12">
                    <el-col :span="9">{{ $t("croptab.harvest_month") }}</el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Harvest_Month"
                      />
                    </el-col>
                    <el-col :span="3">{{ $t("croptab.harvest_day") }}</el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Harvest_Day"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="">
                  <el-checkbox
                    v-model="content_use.Transplanting"
                    true-label="1"
                    false-label="0"
                    size="mini"
                    >{{ $t("croptab.transplanting") }}</el-checkbox
                  >
                </el-form-item>
                <el-form-item :label="$t('croptab.harvest_mode')">
                  <el-select
                    v-model="content_use.Year_of_harvest"
                    :placeholder="$t('buttons.select')"
                    size="mini"
                  >
                    <el-option size="mini" label="in this year" :value="1" />
                    <el-option size="mini" label="in next year" :value="2" />
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('croptab.fraction')">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    :min="0"
                    :max="1"
                    v-model="content_use.Ground_Residue"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="15">
                <el-divider content-position="left">{{
                  $t("croptab.crop_parameters")
                }}</el-divider>
                <el-form-item>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="12"> </el-col>
                    <el-col :span="12">
                      <el-row type="flex" :gutter="2">
                        <el-col :span="6">{{ $t("cuttingtab.grain") }}</el-col>
                        <el-col :span="6">{{ $t("cuttingtab.leaf") }}</el-col>
                        <el-col :span="6">{{ $t("cuttingtab.stem") }}</el-col>
                        <el-col :span="6">{{ $t("cuttingtab.root") }} </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="12">
                      {{ $t("croptab.max_biomass_production") }}
                    </el-col>
                    <el-col :span="12">
                      <el-row type="flex" :gutter="2">
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Yield"
                          />
                        </el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="biomass_L"
                            disabled
                          />
                        </el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="biomass_S"
                            disabled
                          />
                        </el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="biomass_R"
                            disabled
                          />
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="12"
                      >{{ $t("croptab.biomass_fraction") }}
                      <span :class="sum_of_fraction > 1 ? 'large-sum' : ''"
                        >(sum={{ sum_of_fraction }})</span
                      >
                    </el-col>
                    <el-col :span="12">
                      <el-row type="flex" :gutter="2">
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Grain_fraction"
                          />
                        </el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Leaf_fraction"
                          />
                        </el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Stem_fraction"
                          />
                        </el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Root_fraction"
                          />
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="12"
                      >{{ $t("croptab.biomass_C/N_ratio") }}
                    </el-col>
                    <el-col :span="12">
                      <el-row type="flex" :gutter="2">
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Grain_CN"
                          />
                        </el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Leaf_CN"
                          />
                        </el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Stem_CN"
                          />
                        </el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Root_CN"
                          />
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item :label="$t('croptab.CO2_increase_rate')">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.CO2_increase_rate"
                  />
                </el-form-item>
                <el-form-item :label="$t('croptab.TDD')">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.TDD"
                  />
                </el-form-item>
                <el-form-item :label="$t('croptab.water_requirement')">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.Water_requirement"
                  />
                </el-form-item>
                <el-form-item :label="$t('croptab.N_fixation')">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.N_fixation"
                  />
                </el-form-item>
                <el-form-item :label="$t('croptab.optimum_T')">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.Optimum_T"
                  />
                </el-form-item>
                <el-form-item :label="$t('croptab.vascularity')">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    :min="0"
                    :max="1"
                    v-model="content_use.Vascularity"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row
              type="flex"
              :gutter="0"
              style="justify-content: flex-end; margin-top: 1rem"
            >
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="modifyRow(content_use, current_content_list_id)"
                  :disabled="current_content_list_id === null"
                  >{{ $t("croppingtabs.confirm_update") }} {{ tab_name }}
                  {{
                    current_content_list_id !== null
                      ? "(ID=" + content_use[tab_col_id] + ")"
                      : ""
                  }}</el-button
                >
                <el-button size="mini" type="info" @click="addRow(content_use)"
                  >{{ $t("croppingtabs.template_create_new") }}
                  {{ tab_name }}</el-button
                >
              </el-button-group>
            </el-row>
            {{ content_use }}
            <el-divider></el-divider>

            <el-row type="flex" max-height="150">
              <el-table
                :data="content_ex"
                style="width: 100%"
                id="content-list"
              >
                <el-table-column prop="id" label="ID" fixed="left">
                </el-table-column>
                <el-table-column :prop="tab_col_id" :label="tab_name + ' ID'">
                </el-table-column>
                <el-table-column
                  prop="Crop_Type"
                  :label="$t('croptab.crop_type')"
                >
                </el-table-column>
                <el-table-column
                  :label="$t('buttons.operation')"
                  fixed="right"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="info"
                      @click="selectRow(scope.row.id)"
                      >{{ $t("buttons.change") }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteRow(scope.row.id)"
                      >{{ $t("buttons.delete") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import cropping_base from "@/mixins/cropping_base";
import config_template from "@/data/form_config_template";
import apiURL from "@/data/api";
import { mapGetters } from "vuex";
export default {
  name: "CropTab",
  components: {},
  mixins: [cropping_base],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tab_name: "Crop",
      tab_col_id: "Crop_ID",
      tab_number_key: "Crop_Number",
      tab_content_key: "Crop_Info",
      imported: false,
      current_content_list_id: null,
      config_list: [],
      content_template: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Crop
            .Crop_Info[0]
        )
      ),
      content_use: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Crop
            .Crop_Info[0]
        )
      ),
      crop_info: {},

      biomass_L: 0,
      biomass_S: 0,
      biomass_R: 0,
    };
  },
  computed: {
    ...mapGetters(["headers4reqs"]),
    sum_of_fraction() {
      return (
        this.content_use.Grain_fraction +
        this.content_use.Leaf_fraction +
        this.content_use.Stem_fraction +
        this.content_use.Root_fraction
      );
    },
    fraction_key() {
      return (
        this.content_use.Grain_fraction.toString() +
        this.content_use.Leaf_fraction.toString() +
        this.content_use.Stem_fraction.toString() +
        this.content_use.Root_fraction.toString() +
        this.content_use.Yield.toString()
      );
    },
  },
  watch: {
    fraction_key(nv) {
      // 如果任何一个fraction 修改了，我们将同步更新上面对应的biomass数据
      const k = this.content_use.Yield / this.content_use.Grain_fraction;
      this.biomass_L = k * this.content_use.Leaf_fraction;
      this.biomass_S = k * this.content_use.Stem_fraction;
      this.biomass_R = k * this.content_use.Root_fraction;
      return nv;
    },
    sum_of_fraction(nv, ov) {
      console.log(nv, ov);
      if (nv !== 1) {
        this.$message.error("各Fraction之和必须为1！请修改对应数值。");
      }
    },
  },
  mounted() {
    let vm = this;
    this.$get_api(apiURL.data.crop_info, null, this.headers4reqs, {}).then(
      (res) => {
        console.log(JSON.stringify(res));
        vm.crop_info = res;
      }
    );
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "../block_styles";
#content-list {
  td {
    padding: 0.5rem 0;
  }
}
.large-sum {
  color: red;
}
</style>
