<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ tab_name }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="22">
              <el-col :span="9">
                <el-row type="flex" :gutter="12">
                  <el-col :span="24">
                    <p>
                      {{ $t("manuretab.application") }}
                      {{ value[tab_number_key] }}
                    </p>
                  </el-col>
                </el-row>

                <el-form-item label="">
                  <el-row type="flex" :gutter="12">
                    <el-col :span="9">{{ $t("croppingtabs.month") }}</el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Manure_Month"
                      />
                    </el-col>
                    <el-col :span="3">{{ $t("croppingtabs.day") }}</el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Manure_Day"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item :label="$t('manuretab.manure_type')">
                  <el-select
                    v-model="content_use.Manure_Type"
                    :placeholder="$t('buttons.select')"
                    size="mini"
                  >
                    <el-option size="mini" label="farmyard manure" value="1" />
                    <el-option size="mini" label="green manure" value="2" />
                    <el-option size="mini" label="straw" value="3" />
                    <el-option
                      size="mini"
                      :label="$t('manuretab.slurry_animal_waste')"
                      value="4"
                    />
                    <el-option size="mini" label="compost" value="5" />
                    <el-option size="mini" label="bean cake" value="6" />
                    <el-option size="mini" label="human waste" value="7" />
                    <el-option size="mini" label="poultry waste" value="8" />
                    <el-option size="mini" label="sewage sludge" value="9" />
                    <el-option
                      size="mini"
                      label="meat or blood meal"
                      value="10"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="">
              <el-row type="flex" :gutter="12">
                <el-col :span="6">{{
                  $t("manuretab.manure_C_N_ratio")
                }}</el-col>
                <el-col :span="6">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.Manure_C_N_Ratio"
                  />
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="12">
                <el-col :span="6">{{ $t("manuretab.manure_amount") }}</el-col>
                <el-col :span="6">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.Manure_Amount"
                  />
                </el-col>
                <el-col :span="6">{{ $t("manuretab.manure_NH4") }}</el-col>
                <el-col :span="6">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.Manure_NH4"
                  />
                </el-col>
              </el-row>
              <el-row type="flex" :gutter="12">
                <el-col :span="6">{{ $t("manuretab.manure_orgN") }}</el-col>
                <el-col :span="6">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.Manure_OrgN"
                  />
                </el-col>
                <el-col :span="6">{{ $t("manuretab.manure_NO3") }}</el-col>
                <el-col :span="6">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.Manure_NO3"
                  />
                </el-col>
              </el-row>

              <el-row type="flex" :gutter="12">
                <el-col :span="4">{{
                  $t("manuretab.application_method")
                }}</el-col>
                <el-col :span="15">
                  <el-radio v-model="content_use.Manure_Method" label="1">{{
                    $t("manuretab.surface spread")
                  }}</el-radio>
                  <el-radio v-model="content_use.Manure_Method" label="2">{{
                    $t("manuretab.incorporation")
                  }}</el-radio>
                  <el-radio v-model="content_use.Manure_Method" label="2">{{
                    $t("manuretab.injection")
                  }}</el-radio>
                </el-col>

                <el-col :span="3">{{ $t("manuretab.depth") }}</el-col>
                <el-col :span="2">
                  <el-input-number
                    size="mini"
                    :controls="false"
                    v-model="content_use.Manure_depth"
                  />
                </el-col>
              </el-row>
            </el-form-item>
            <el-row
              type="flex"
              :gutter="0"
              style="justify-content: flex-end; margin-top: 1rem"
            >
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="modifyRow(content_use, current_content_list_id)"
                  :disabled="current_content_list_id === null"
                  >{{ $t("croppingtabs.confirm_update") }} {{ tab_name }}
                  {{
                    current_content_list_id !== null
                      ? "(ID=" + content_use[tab_col_id] + ")"
                      : ""
                  }}</el-button
                >
                <el-button size="mini" type="info" @click="addRow(content_use)"
                  >{{ $t("croppingtabs.template_create_new") }}
                  {{ tab_name }}</el-button
                >
              </el-button-group>
            </el-row>
            {{ content_use }}
            <el-divider></el-divider>

            <el-row type="flex" max-height="150">
              <el-table
                :data="content_ex"
                style="width: 100%"
                id="content-list"
              >
                <el-table-column prop="id" label="ID" fixed="left">
                </el-table-column>
                <el-table-column :prop="tab_col_id" :label="tab_name + ' ID'">
                </el-table-column>
                <el-table-column prop="Manure_Month" label="Month">
                </el-table-column>
                <el-table-column prop="Manure_Day" label="Day" />
                <el-table-column prop="Manure_Type" label="Type" />
                <el-table-column prop="Manure_Amount" label="Manure C" />
                <el-table-column prop="Manure_C_N_Ratio" label="C/N" />
                <el-table-column prop="Manure_OrgN" label="Manure N" />
                <el-table-column
                  :label="$t('buttons.operation')"
                  fixed="right"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="info"
                      @click="selectRow(scope.row.id)"
                      >{{ $t("buttons.change") }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteRow(scope.row.id)"
                      >{{ $t("buttons.delete") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import cropping_base from "@/mixins/cropping_base";
import config_template from "@/data/form_config_template";

export default {
  name: "ManureTab",
  components: {},
  mixins: [cropping_base],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tab_name: "Manure",
      tab_col_id: "Manure_ID",
      tab_number_key: "Manure_Number",
      tab_content_key: "Manure_Management",
      imported: false,
      current_content_list_id: null,
      config_list: [],
      content_template: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Manure
            .Manure_Management[0]
        )
      ),
      content_use: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Manure
            .Manure_Management[0]
        )
      ),
    };
  },
  mounted() {
    // let vm = this;
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "../block_styles";
#content-list {
  td {
    padding: 0.5rem 0;
  }
}
</style>
