var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"form",attrs:{"model":_vm.value,"label-width":"150px","labelPosition":"top"}},[_c('el-row',{staticStyle:{"margin-top":"1rem"},attrs:{"type":"flex","gutter":12}},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"form-block-unit"},[_c('p',{staticClass:"form-block-head"},[_vm._v(_vm._s(_vm.$t("block.data_header.upload")))]),_c('div',{staticClass:"form-block-body"},[_c('el-row',{attrs:{"type":"flex","gutter":12}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('block.data_header.simulation_name')}},[_c('el-input',{attrs:{"placeholder":"Simulation Name","size":"mini"},model:{value:(_vm.config_json.Simulation_Name),callback:function ($$v) {_vm.$set(_vm.config_json, "Simulation_Name", $$v)},expression:"config_json.Simulation_Name"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('block.data_header.comment')}},[_c('el-input',{attrs:{"placeholder":_vm.$t('block.data_header.comment_palceholder'),"size":"mini"},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('block.data_header.remote_node')}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"size":"mini","placeholder":_vm.remote_nodes.length === 0
                      ? _vm.$t('block.data_header.remote_node_add')
                      : _vm.$t('block.data_header.remote_node_select'),"disabled":false},on:{"focus":_vm.getRemoteNodes},model:{value:(_vm.remote_node_id),callback:function ($$v) {_vm.remote_node_id=$$v},expression:"remote_node_id"}},_vm._l((_vm.remote_nodes),function(item){return _c('el-option',{key:item.id,staticStyle:{"margin-top":"0.2rem"},attrs:{"label":item.name,"value":item.id}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"worker-status"},[(item.id in _vm.nodes_status)?_c('WorkerStatusTag',{attrs:{"workers_status":_vm.nodes_status[item.id].workers_status}}):_c('el-tag',{attrs:{"type":"danger"}})],1),_c('span',{staticStyle:{"float":"right","color":"#8492a6","font-size":"13px"}},[_vm._v(_vm._s(item.ip)+":"+_vm._s(item.port))])])}),1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('block.data_header.model_directory')}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"size":"mini","placeholder":_vm.remote_models.length === 0
                      ? _vm.$t('block.data_header.remote_model_add')
                      : _vm.$t('block.data_header.remote_model_select'),"disabled":false},on:{"focus":_vm.getRemoteModels},model:{value:(_vm.remote_model),callback:function ($$v) {_vm.remote_model=$$v},expression:"remote_model"}},_vm._l((_vm.remote_models),function(item,key){return _c('el-option',{key:key,attrs:{"label":item,"value":item}})}),1)],1)],1)],1),_c('el-row',{attrs:{"type":"flex","gutter":12}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":_vm.$t('block.data_header.data_source')}},[_c('el-radio',{attrs:{"label":true},model:{value:(_vm.select_from_uploaded_data),callback:function ($$v) {_vm.select_from_uploaded_data=$$v},expression:"select_from_uploaded_data"}},[_vm._v(_vm._s(_vm.$t("block.upload_and_select")))]),_c('el-radio',{attrs:{"label":false},model:{value:(_vm.select_from_uploaded_data),callback:function ($$v) {_vm.select_from_uploaded_data=$$v},expression:"select_from_uploaded_data"}},[_vm._v(_vm._s(_vm.$t("block.upload_new")))])],1)],1)],1),(_vm.select_from_uploaded_data === false)?_c('el-row',{staticClass:"upload-block",attrs:{"id":"Upload_Data"}},[_c('el-col',{attrs:{"span":12}},[_c('el-upload',{ref:"upload",staticStyle:{"height":"100px"},attrs:{"accept":".zip","headers":this.headers4reqs,"action":_vm.upload_url,"list-type":"text","auto-upload":false,"limit":1,"on-remove":_vm.checkRemovingFile,"on-change":_vm.checkRemainingFile,"on-success":_vm.handleSubmitTask}},[_c('span',[_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"default"},slot:"default"}),_vm._v(" "+_vm._s(_vm.$t("block.add_zip"))+" ")])])],1)],1):_c('el-row',{staticClass:"upload-block",attrs:{"id":"Select_Data"}},[_c('el-col',{attrs:{"span":12}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"size":"mini","placeholder":_vm.data_source.length === 0
                    ? _vm.$t('block.data_header.select_null')
                    : _vm.$t('block.data_header.select_data'),"disabled":_vm.data_source.length === 0},model:{value:(_vm.data_id_selected),callback:function ($$v) {_vm.data_id_selected=$$v},expression:"data_id_selected"}},_vm._l((_vm.data_source),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1),_c('el-divider'),_c('el-row',{staticClass:"one-line-complex-form",attrs:{"type":"flex","gutter":6}},[_c('el-col',{staticStyle:{"display":"flex","justify-content":"flex-end"},attrs:{"span":24}},[_c('el-button',{staticStyle:{"width":"150px"},attrs:{"type":"info"},on:{"click":_vm.handleConfigPreview}},[_vm._v(" "+_vm._s(_vm.$t("block.preview"))+" ")]),(_vm.select_from_uploaded_data === false)?_c('el-button',{staticStyle:{"width":"200px"},attrs:{"type":"primary","disabled":_vm.confirm_works === false ||
                  _vm.uploading === true ||
                  _vm.remote_model == null ||
                  _vm.remote_node_id == null},on:{"click":_vm.handleUpload}},[_vm._v(" "+_vm._s(_vm.$t("block.create_and_upload"))+" ")]):_c('el-button',{staticStyle:{"width":"200px"},attrs:{"type":"primary","disabled":_vm.submit_task_in_processing ||
                  _vm.data_id_selected == null ||
                  _vm.remote_model == null ||
                  _vm.remote_node_id == null},on:{"click":_vm.handleSubmitWithExistingData}},[_vm._v(" "+_vm._s(_vm.$t("taskcenter.create_task"))+" ")])],1)],1)],1)])])],1),_c('el-dialog',{attrs:{"title":_vm.$t('block.preview'),"visible":_vm.dialogConfigJsonVisible},on:{"update:visible":function($event){_vm.dialogConfigJsonVisible=$event},"open":function($event){_vm.config_json_rendered = JSON.stringify(_vm.task_config, null, 2)},"close":function($event){_vm.config_json_rendered = ''}}},[_c('div',{staticClass:"json-block"},[_c('pre',{domProps:{"innerHTML":_vm._s(_vm.config_json_rendered)}})]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.download_current_config(_vm.task_config)}}},[_vm._v(_vm._s(_vm.$t("buttons.download_current_config")))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }