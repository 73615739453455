<template>
  <div class="home">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'TaskList' }">{{
        $t("buttons.task_center")
      }}</el-breadcrumb-item>
      <el-breadcrumb-item
        >{{ $t("taskcenter.create_task") }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-block">
      <div class="page-block-header">
        <div class="content-title">
          <p>
            {{ $t("taskcenter.create_task")
            }}{{
              $route.query.type_create === "copy"
                ? "（$t('taskcenter.create_task_copied') + $route.query.task_id_copied ）"
                : ""
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="page-content-fill" v-loading="content_in_processing">
      <div style="height: 100%" v-if="task_submitted === false">
        <el-tabs
          id="create-task-form"
          v-model="active_step"
          @tab-click="handleTabClick"
          style="height: 100%; display: flex; flex-direction: column"
        >
          <el-tab-pane
            :label="$t('taskcenter.import-config')"
            name="import_config"
          >
            <ImportConfigFormBlock
              @go-next="active_step = 'site'"
              v-model="task_config"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('taskcenter.site')" name="site">
            <SiteBlock
              v-model="task_config.Model_Config.Site_Data"
              @go-next="active_step = 'climate'"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('taskcenter.climate')" name="climate">
            <ClimateFormBlock
              v-model="task_config.Model_Config.Climate_Data"
              :site_info="task_config.Model_Config.Site_Data"
              @go-next="active_step = 'soil'"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('taskcenter.soil')" name="soil">
            <SoilFormBlock
              v-model="task_config.Model_Config.Soil_Data"
              :site_info="task_config.Model_Config.Site_Data"
              :tab_activated="active_step === 'soil'"
              @go-next="active_step = 'cropping'"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('taskcenter.cropping')" name="cropping">
            <CroppingFormBlock
              v-model="task_config.Model_Config.Management"
              :site_data_simulated_years="
                task_config.Model_Config.Site_Data.Simulated_Year
              "
              @go-next="active_step = 'upload'"
            />
          </el-tab-pane>

          <el-tab-pane
            :label="$t('taskcenter.data-model-and-node')"
            name="upload"
          >
            <UploadBlock :config_json="task_config" />
          </el-tab-pane>
        </el-tabs>
      </div>

      <div v-else>
        <p>
          <strong> {{ $t("taskcenter.task") }}# {{ task_id }} </strong>
          {{ $t("taskcenter.task_committed_info") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import apiURL from "@/data/api";
import config_template from "@/data/form_config_template";
import { mapGetters, mapState } from "vuex";

import SoilFormBlock from "./FormBlocks/SoilBlock";
import ClimateFormBlock from "./FormBlocks/ClimateBlock";
import ImportConfigFormBlock from "./FormBlocks/ImportConfigBlock";
import CroppingFormBlock from "./FormBlocks/CroppingBlock";
import UploadBlock from "./FormBlocks/UploadBlock";
import SiteBlock from "./FormBlocks/SiteBlock";

export default {
  name: "TaskCreate",
  components: {
    ImportConfigFormBlock,
    SoilFormBlock,
    ClimateFormBlock,
    CroppingFormBlock,
    UploadBlock,
    SiteBlock,
  },
  data() {
    return {
      task_id: 6, //TODO 需要去掉id
      priorities: [
        {
          id: 0,
          name: this.$t("uploadblock.priority_high"),
        },
        {
          id: 1,
          name: this.$t("uploadblock.priority_normal"),
        },
        {
          id: 2,
          name: this.$t("uploadblock.priority_low"),
        },
      ],
      // active_step: "import_config",
      active_step: "site",

      uploading: false,
      confirm_works: false,
      task_submitted: false,

      form: {
        priority: 0,
        note: "",
        json_str: "",
      },
      // TODO: 后期去掉默认的json
      form_vod_rules: {
        priority: [
          {
            required: true,
            message: this.$t("uploadblock.priority_input"),
            trigger: "change",
          },
        ],
      },
      task_config: config_template,
    };
  },
  computed: {
    ...mapGetters(["headers4reqs"]),
    ...mapState({ app_name: (state) => state.app_name }),
    upload_url() {
      return (
        process.env.VUE_APP_API_BASE_URL + apiURL.stage + apiURL.data.upload
      );
    },
  },
  beforeMount() {
    if (this.$route.query.type_create === "copy") {
      const task_id_copied = this.$route.query.task_id_copied;
      this.content_in_processing = true;
      this.$get_api(
        apiURL.task.configuration.replace("task_id", task_id_copied),
        null,
        this.headers4reqs,
        {}
      ).then((res) => {
        res.Simulation_Name = "Copied from " + task_id_copied;
        this.active_step = "upload";
        this.task_config = res;
        console.log(res);
        this.content_in_processing = false;
      });

      return task_id_copied;
    } else {
      this.task_config = config_template;
    }
  },
  methods: {
    handleChangeTmp(x) {
      console.log(x);
    },
    handleTabClick() {},
    scrollFix(hashbang) {
      location.hash = hashbang;
    },
    scrollToRotation(i) {
      var elmnt = document.getElementById(i);
      elmnt.scrollIntoView();
    },
    handlGoBackTaskList() {
      this.$router.push({ name: "TaskList" });
    },
    handlRemoveAndGoBackTaskList() {
      if (this.task_id !== null) {
        this.$delete_api(
          apiURL.task.details.replace("task_id", this.task_id),
          null,
          this.headers4reqs
        ).then((res) => {
          this.$router.push({ name: "TaskList" });
          return res;
        });
      } else {
        this.$router.push({ name: "TaskList" });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/style/main";
.config-block-head-big {
  font-size: 1.5rem !important;
}
.config-step {
  overflow-x: hidden;
  padding-right: $card-border-radius * 2;
}
.el-tabs__content {
  flex: 1;
  background: white;
}
</style>


<style lang="scss" >
@import "@/style/main";
#create-task-form {
  .el-tabs__content {
    flex: 1;
    background: white;
    overflow-y: scroll;
    padding-right: 0.5rem;
  }
}
</style>

