<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ tab_name }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="22">
              <el-col :span="6">
                <el-form-item label="">
                  <el-row type="flex" style="margin-top: 1rem">
                    <el-radio
                      v-model="content_use.Fertilization_option"
                      label="0"
                      >{{ $t("fertilizationtab.manual") }}</el-radio
                    >
                  </el-row>
                  <el-row type="flex" style="margin-top: 1rem">
                    <el-radio
                      v-model="content_use.Fertilization_option"
                      label="1"
                      >{{ $t("fertilizationtab.auto_fertilization") }}</el-radio
                    >
                  </el-row>
                  <el-row type="flex" style="margin-top: 1rem">
                    <el-radio
                      v-model="content_use.Fertilization_option"
                      label="2"
                      >{{
                        $t("fertilizationtab.precision_fertilization")
                      }}</el-radio
                    >
                  </el-row>
                  <el-row type="flex" style="margin-top: 1rem">
                    <el-radio
                      v-model="content_use.Fertilization_option"
                      label="3"
                      >{{ $t("fertilizationtab.fertigation") }}</el-radio
                    >
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="18">
                <el-form-item label="Application date">
                  <el-row type="flex" :gutter="8">
                    <el-col :span="6">{{ $t("croppingtabs.month") }}</el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Fertilization_Month"
                    /></el-col>
                    <el-col :span="6">{{ $t("croppingtabs.day") }}</el-col>
                    <el-col :span="6">
                      <el-input-number
                        size="mini"
                        :controls="false"
                        v-model="content_use.Fertilization_Day"
                    /></el-col>
                  </el-row>
                </el-form-item>
                <el-row type="flex" :gutter="12">
                  <el-col :span="12">
                    <el-form-item label="Application depth">
                      <el-radio
                        v-model="content_use.Fertilization_Method"
                        :label="0"
                        >{{ $t("fertilizationtab.surface") }}</el-radio
                      >
                      <el-radio
                        v-model="content_use.Fertilization_Method"
                        :label="1"
                        >{{ $t("fertilizationtab.injection") }}</el-radio
                      >
                    </el-form-item></el-col
                  >
                  <el-col :span="12">
                    <el-form-item :label="$t('fertilizationtab.depth')">
                      <el-input-number
                        v-model="content_use.Fertilization_Depth"
                        :controls="false"
                        size="mini"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-form-item>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col :span="6">{{
                          $t("fertilizationtab.urea")
                        }}</el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Urea_Amount"
                        /></el-col>
                        <el-col :span="6">{{
                          $t("fertilizationtab.anhydrous ammonia")
                        }}</el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Anh_Amount"
                        /></el-col>
                      </el-row>

                      <el-row type="flex" :gutter="8">
                        <el-col :span="6">{{
                          $t("fertilizationtab.nitrate")
                        }}</el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Nitrate_Amount"
                        /></el-col>
                        <el-col :span="6">{{
                          $t("fertilizationtab.ammonium")
                        }}</el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_AmNi_Amount"
                        /></el-col>
                      </el-row>
                      <el-row type="flex" :gutter="8">
                        <el-col :span="6">{{
                          $t("fertilizationtab.sulphate")
                        }}</el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_NH42SO4_Amount"
                        /></el-col>
                        <el-col :span="6">{{
                          $t("fertilizationtab.phosphate")
                        }}</el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_NH42HPO4_Amount"
                        /></el-col>
                      </el-row>
                      <el-row type="flex" :gutter="8">
                        <el-col :span="6">{{
                          $t("fertilizationtab.ammonium bicarbonate")
                        }}</el-col>
                        <el-col :span="6">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Abi_Amount"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider content-position="left">{{
              $t("fertilizationtab.additional_method")
            }}</el-divider>
            <el-row type="flex" :gutter="12" style="margin-bottom: 1rem">
              <el-col :span="8">
                <el-checkbox
                  :true-label="1"
                  :false-label="0"
                  v-model="content_use.Controlled_release_fertlizer"
                  >{{
                    $t("fertilizationtab.controlled_release_fertlizer")
                  }}</el-checkbox
                >
              </el-col>
              <!-- TODO 修改成多选 -->
              <el-col :span="8"></el-col>
              <el-col :span="8">
                <el-row type="flex" :gutter="12">
                  <el-col
                    :span="14"
                    style="
                      padding-left: 6px;
                      padding-right: 6px;
                      display: flex;
                      align-items: center;
                    "
                    >{{
                      $t("fertilizationtab.days_for_total_N_release")
                    }}</el-col
                  >
                  <el-col :span="10"
                    ><el-input-number
                      v-model="content_use.Fertilization_Release_rate"
                      :controls="false"
                      size="mini"
                      :disabled="
                        Number(content_use.Controlled_release_fertlizer) === 0
                      "
                  /></el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="12" style="margin-bottom: 1rem">
              <el-col :span="8">
                <el-checkbox
                  :true-label="1"
                  :false-label="0"
                  v-model="content_use.Using_Nitrification_Inhibitor"
                  >{{
                    $t("fertilizationtab.using_nitrification_inhibitor")
                  }}</el-checkbox
                ></el-col
              >
              <el-col :span="8">
                <el-row type="flex" :gutter="12">
                  <el-col
                    :span="14"
                    style="
                      padding-left: 6px;
                      padding-right: 6px;
                      display: flex;
                      align-items: center;
                    "
                    >{{ $t("fertilizationtab.efficiency") }}</el-col
                  >
                  <el-col :span="10"
                    ><el-input-number
                      :controls="false"
                      size="mini"
                      :min="0"
                      :max="1"
                      :disabled="
                        content_use.Using_Nitrification_Inhibitor === 0
                      "
                      v-model="content_use.Fertilization_Inhibitor_efficiency"
                  /></el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row type="flex" :gutter="12">
                  <el-col
                    :span="14"
                    style="
                      padding-left: 6px;
                      padding-right: 6px;
                      display: flex;
                      align-items: center;
                    "
                    >{{ $t("fertilizationtab.efficiency_days") }}</el-col
                  >
                  <el-col :span="10"
                    ><el-input-number
                      :disabled="
                        content_use.Using_Nitrification_Inhibitor === 0
                      "
                      v-model="content_use.Fertilization_Inhibitor_duration"
                      :controls="false"
                      size="mini"
                  /></el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="12" style="margin-bottom: 1rem">
              <el-col :span="8">
                <el-checkbox
                  :true-label="1"
                  :false-label="0"
                  v-model="content_use.Using_Urease_Inhibitor"
                  >{{
                    $t("fertilizationtab.using urease inhibitor")
                  }}</el-checkbox
                >
              </el-col>
              <el-col :span="8">
                <el-row type="flex" :gutter="12">
                  <el-col
                    :span="14"
                    style="
                      padding-left: 6px;
                      padding-right: 6px;
                      display: flex;
                      align-items: center;
                    "
                    >{{ $t("fertilizationtab.efficiency") }}</el-col
                  >
                  <el-col :span="10"
                    ><el-input-number
                      :controls="false"
                      size="mini"
                      :min="0"
                      :max="1"
                      :disabled="content_use.Using_Urease_Inhibitor === 0"
                      v-model="content_use.Fertilization_Urease_efficiency"
                  /></el-col>
                </el-row>
              </el-col>
              <el-col :span="8">
                <el-row type="flex" :gutter="12">
                  <el-col
                    :span="14"
                    style="
                      padding-left: 6px;
                      padding-right: 6px;
                      display: flex;
                      align-items: center;
                    "
                    >{{ $t("fertilizationtab.efficiency_days") }}</el-col
                  >
                  <el-col :span="10"
                    ><el-input-number
                      :controls="false"
                      size="mini"
                      v-model="content_use.Fertilization_Urease_duration"
                      :disabled="content_use.Using_Urease_Inhibitor === 0"
                  /></el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row
              type="flex"
              :gutter="0"
              style="justify-content: flex-end; margin-top: 1rem"
            >
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="modifyRow(content_use, current_content_list_id)"
                  :disabled="current_content_list_id === null"
                  >{{ $t("croppingtabs.confirm_update") }} {{ tab_name }}
                  {{
                    current_content_list_id !== null
                      ? "(ID=" + content_use[tab_col_id] + ")"
                      : ""
                  }}</el-button
                >
                <el-button size="mini" type="info" @click="addRow(content_use)"
                  >{{ $t("croppingtabs.template_create_new") }}
                  {{ tab_name }}</el-button
                >
              </el-button-group>
            </el-row>
            {{ content_use }}
            <el-divider></el-divider>

            <el-row type="flex" max-height="150">
              <el-table
                :data="content_ex"
                style="width: 100%"
                id="content-list"
              >
                <el-table-column prop="id" label="ID" fixed="left">
                </el-table-column>
                <el-table-column :prop="tab_col_id" :label="tab_name + ' ID'">
                </el-table-column>
                <el-table-column
                  :label="$t('buttons.operation')"
                  fixed="right"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="info"
                      @click="selectRow(scope.row.id)"
                      >{{ $t("buttons.change") }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteRow(scope.row.id)"
                      >{{ $t("buttons.delete") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import cropping_base from "@/mixins/cropping_base";
import config_template from "@/data/form_config_template";

export default {
  name: "FertilizationTab",
  components: {},
  mixins: [cropping_base],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tab_name: "Fertilization",
      tab_col_id: "Fertilization_ID",
      tab_number_key: "Fertilization_Number",
      tab_content_key: "Fertilization_Management",
      imported: false,
      current_content_list_id: null,
      config_list: [],
      content_template: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0]
            .Fertilization.Fertilization_Management[0]
        )
      ),
      content_use: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0]
            .Fertilization.Fertilization_Management[0]
        )
      ),
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "../block_styles";
#content-list {
  td {
    padding: 0.5rem 0;
  }
}
</style>
