<template>
  <el-tabs v-model="active_tab" @tab-click="handleClick">
    <el-tab-pane :label="$t('framingpractices.crop')" name="Crop"
      ><CropTab v-model="value.Crop" />
    </el-tab-pane>
    <el-tab-pane :label="$t('framingpractices.tillage')" name="Tillage">
      <TillageTab v-model="value.Tillage" />
    </el-tab-pane>
    <el-tab-pane :label="$t('framingpractices.fertilization')" name="Fertilization">
      <FertilizationTab v-model="value.Fertilization" />
    </el-tab-pane>
    <el-tab-pane :label="$t('framingpractices.manure')" name="Manure">
      <ManureTab v-model="value.Manure" />
    </el-tab-pane>
    <el-tab-pane :label="$t('framingpractices.irrigation')" name="Irrigation">
      <IrrigationTab v-model="value.Irrigation" />
    </el-tab-pane>
    <el-tab-pane :label="$t('framingpractices.flooding')" name="Flooding">
      <FloodingTab v-model="value.Flooding" />
    </el-tab-pane>
    <el-tab-pane :label="$t('framingpractices.film_mulch')" name="Film mulch">
      <PlasticulturalTab v-model="value.Plasticultural" />
    </el-tab-pane>
    <el-tab-pane :label="$t('framingpractices.grazing')" name="Grazing">
      <GrazingTab v-model="value.Grazing" />
    </el-tab-pane>
    <el-tab-pane :label="$t('framingpractices.cutting')" name="Cutting">
      <CuttingTab v-model="value.Cutting" />
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import CropTab from "./CropTab";
import TillageTab from "./TillageTab";
import FertilizationTab from "./FertilizationTab";
import ManureTab from "./ManureTab";
import IrrigationTab from "./IrrigationTab";
import FloodingTab from "./FloodingTab";
import GrazingTab from "./GrazingTab";
import CuttingTab from "./CuttingTab";
import PlasticulturalTab from "./PlasticulturalTab";

export default {
  name: "FarmingPractices",
  components: {
    CropTab,
    TillageTab,
    FertilizationTab,
    ManureTab,
    IrrigationTab,
    FloodingTab,
    GrazingTab,
    CuttingTab,
    PlasticulturalTab,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      active_tab: "Crop",
    };
  },
  computed: {},
  methods: {
    handleClick() {},
  },
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
@import "@/style/main";
@import "../block_styles";
</style>
