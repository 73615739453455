<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ tab_name }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="22">
              <el-col :span="6">
                <el-form-item :label="$t('floodingtab.water_control')">
                  <el-row type="flex" style="margin-top: 1rem">
                    <el-radio v-model="content_use.Water_control" :label="0">{{
                      $t("floodingtab.scheduled_flood")
                    }}</el-radio>
                  </el-row>
                  <el-row type="flex" style="margin-top: 1rem">
                    <el-radio v-model="content_use.Water_control" :label="1">{{
                      $t("floodingtab.rainfed")
                    }}</el-radio>
                  </el-row>
                  <el-row type="flex" style="margin-top: 1rem">
                    <el-radio v-model="content_use.Water_control" :label="2">{{
                      $t("floodingtab.observed_water_table_data")
                    }}</el-radio>
                  </el-row>
                  <el-row type="flex" style="margin-top: 1rem">
                    <el-radio v-model="content_use.Water_control" :label="3">{{
                      $t("floodingtab.empirical parameters")
                    }}</el-radio>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="18">
                <el-form-item label="">
                  <el-row type="flex" :gutter="12">
                    <el-col :span="12">
                      <el-row type="flex" :gutter="8">
                        <el-col :span="5">{{ $t("floodingtab.start") }}</el-col>
                        <el-col :span="4">{{
                          $t("croppingtabs.month")
                        }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Flood_Month"
                        /></el-col>
                        <el-col :span="4">{{ $t("croppingtabs.day") }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Flood_Day"
                        /></el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="12">
                      <el-row type="flex" :gutter="8">
                        <el-col :span="5">{{ $t("floodingtab.end") }}</el-col>
                        <el-col :span="4">{{
                          $t("croppingtabs.month")
                        }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Drain_Month"
                        /></el-col>
                        <el-col :span="4">{{ $t("croppingtabs.day") }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Drain_Day"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="">
                  <el-row type="flex" :gutter="8">
                    <el-col :span="12">
                      <el-radio
                        v-model="content_use.Fertilization_option"
                        label="2"
                        >{{ $t("floodingtab.continuous_flooding") }}</el-radio
                      >
                    </el-col>
                    <el-col :span="12">
                      <el-radio
                        v-model="content_use.Fertilization_option"
                        label="2"
                        >{{ $t("floodingtab.dry_flooding") }}</el-radio
                      ></el-col
                    >
                  </el-row>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="">
                  <el-row type="flex" :gutter="8">
                    <el-col
                      :span="12"
                      style="
                        padding-left: 6px;
                        padding-right: 6px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      {{ $t("floodingtab.water_gather_index") }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        v-model="content_use.Water_gather_index"
                        :controls="false"
                        size="mini"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item label="">
                  <el-row type="flex" :gutter="8">
                    <el-col
                      :span="12"
                      style="
                        padding-left: 6px;
                        padding-right: 6px;
                        display: flex;
                        align-items: center;
                      "
                    >
                      {{ $t("floodingtab.observed_water_table_data") }}
                    </el-col>
                    <el-col :span="12">
                      <el-input-number
                        v-model="content_use.WT_file"
                        :controls="false"
                        size="mini"
                      />
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="8"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("floodingtab.Initial_WT_depth") }}</el-col
                        >
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Urea_Amount"
                        /></el-col>
                        <el-col
                          :span="8"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{
                            $t("floodingtab.surface_inflow_fraction")
                          }}</el-col
                        >
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Anh_Amount"
                        /></el-col>
                      </el-row>
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="8"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{
                            $t("floodingtab.Lowest_WT_depth_surface")
                          }}</el-col
                        >
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Urea_Amount"
                        /></el-col>
                        <el-col
                          :span="8"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{
                            $t("floodingtab.intensity factor_surface")
                          }}</el-col
                        >
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Anh_Amount"
                        /></el-col>
                      </el-row>
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="8"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{
                            $t("floodingtab.Lowest_WT_depth_ground")
                          }}</el-col
                        >
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Urea_Amount"
                        /></el-col>
                        <el-col
                          :span="8"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{
                            $t("floodingtab.intensity factor_ground")
                          }}</el-col
                        >
                        <el-col :span="4">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Fertilization_Anh_Amount"
                        /></el-col>
                      </el-row>
                      <el-row type="flex">
                        {{ $t("floodingtab.postive_WT") }}
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row type="flex" :gutter="12" style="margin-bottom: 1rem">
              <el-col
                :span="6"
                style="
                  padding-left: 6px;
                  padding-right: 6px;
                  display: flex;
                  align-items: center;
                "
                >{{ $t("floodingtab.water_N") }}
              </el-col>
              <el-col :span="6"
                ><el-input-number
                  :controls="false"
                  size="mini"
                  v-model="content_use.Water_N"
              /></el-col>
              <el-col
                :span="6"
                style="
                  padding-left: 6px;
                  padding-right: 6px;
                  display: flex;
                  align-items: center;
                "
                >{{ $t("floodingtab.leak_rate") }}
              </el-col>
              <el-col :span="6"
                ><el-input-number
                  :controls="false"
                  size="mini"
                  v-model="content_use.Leak_rate"
              /></el-col>
            </el-row>

            <el-row
              type="flex"
              :gutter="0"
              style="justify-content: flex-end; margin-top: 1rem"
            >
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="modifyRow(content_use, current_content_list_id)"
                  :disabled="current_content_list_id === null"
                  >{{ $t("croppingtabs.confirm_update") }} {{ tab_name }}
                  {{
                    current_content_list_id !== null
                      ? "(ID=" + content_use[tab_col_id] + ")"
                      : ""
                  }}</el-button
                >
                <el-button size="mini" type="info" @click="addRow(content_use)"
                  >{{ $t("croppingtabs.template_create_new") }}
                  {{ tab_name }}</el-button
                >
              </el-button-group>
            </el-row>
            {{ content_use }}
            <el-divider></el-divider>

            <el-row type="flex" max-height="150">
              <el-table
                :data="content_ex"
                style="width: 100%"
                id="content-list"
              >
                <el-table-column prop="id" label="ID" fixed="left">
                </el-table-column>
                <el-table-column :prop="tab_col_id" :label="tab_name + ' ID'">
                </el-table-column>
                <el-table-column
                  :label="$t('buttons.operation')"
                  fixed="right"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="info"
                      @click="selectRow(scope.row.id)"
                      >{{ $t("buttons.change") }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteRow(scope.row.id)"
                      >{{ $t("buttons.delete") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import cropping_base from "@/mixins/cropping_base";
import config_template from "@/data/form_config_template";

export default {
  name: "FloodingTab",
  components: {},
  mixins: [cropping_base],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tab_name: "Flooding",
      tab_col_id: "Flooding_ID",
      tab_number_key: "Flooding_Number",
      tab_content_key: "Flooding_Management",
      imported: false,
      current_content_list_id: null,
      config_list: [],
      content_template: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Flooding
            .Flooding_Management[0]
        )
      ),
      content_use: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0].Flooding
            .Flooding_Management[0]
        )
      ),
    };
  },
  mounted() {
    // let vm = this;
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "../block_styles";
#content-list {
  td {
    padding: 0.5rem 0;
  }
}
</style>
