<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">
            {{ $t("configblock.import_header") }}
          </p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="12">
              <el-col :span="24">
                <el-form-item :label="$t('configblock.local_json_init')">
                  <el-upload
                    :on-preview="handlePreviewConfig"
                    :on-remove="handleRemoveConfig"
                    :limit="1"
                    :file-list="config_list"
                    action="#"
                    :auto-upload="false"
                    :multiple="false"
                    :on-change="checkConfig"
                    accept=".json"
                  >
                    <el-button size="small">{{
                      $t("configblock.choose_config")
                    }}</el-button>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="12">
              <el-col :span="24">
                <p v-if="imported">
                  {{ "$t('configblock.success_and_next')" }}
                </p>
              </el-col>
            </el-row>

            <el-divider></el-divider>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col
                :span="24"
                style="display: flex; justify-content: flex-end"
              >
                <el-button
                  style="width: 100px"
                  @click="$emit('go-next')"
                  type="primary"
                >
                  {{ imported ? $t("buttons.next") : $t("buttons.skip") }}
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
export default {
  name: "ImportConfigFormBlock",
  components: {},
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      imported: false,
      config_list: [],
    };
  },
  computed: {},
  methods: {
    handlePreviewConfig() {},
    handleRemoveConfig() {},
    checkConfig(f, fl) {
      let vm = this;
      console.log(f.size);
      console.log(f.raw);
      console.log(typeof f.raw);
      try {
        var reader = new FileReader(); //这里是核心！！！读取操作就是由它完成的。
        reader.readAsText(f.raw); //读取文件的内容

        reader.onload = function() {
          console.log(this.result);
          let config_imported = JSON.parse(this.result);
          // 强制类型转换
          config_imported["Model_Config"]["Site_Data"][
            "Simulated_Year"
          ] = Number(
            config_imported["Model_Config"]["Site_Data"]["Simulated_Year"]
          );
          vm.imported = true;
          vm.$emit("input", config_imported);
        };
      } catch (e) {
        console.log(e);
      }

      return f, fl;
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "./block_styles";
</style>
