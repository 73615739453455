<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ tab_name }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="22">
              <el-col :span="24">
                <el-row
                  type="flex"
                  :gutter="12"
                  style="margin-top: 1rem; margin-bottom: 1rem"
                >
                  <el-col :span="4">{{
                    $t("plasticulturaltab.method")
                  }}</el-col>
                  <el-col :span="15">
                    <el-radio v-model="content_use.Plastic_Method" :label="0">{{
                      $t("plasticulturaltab.greenhouse")
                    }}</el-radio>
                    <el-radio v-model="content_use.Plastic_Method" :label="1">{{
                      $t("plasticulturaltab.plastic_film")
                    }}</el-radio>
                    <el-radio v-model="content_use.Plastic_Method" :label="2">{{
                      $t("plasticulturaltab.none")
                    }}</el-radio>
                  </el-col>
                </el-row>
                <el-form-item label="">
                  <el-row type="flex" :gutter="12">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col :span="5">{{
                          $t("plasticulturaltab.from")
                        }}</el-col>
                        <el-col :span="4">{{
                          $t("croppingtabs.month")
                        }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Plastic_StartMonth"
                        /></el-col>
                        <el-col :span="4">{{ $t("croppingtabs.day") }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Plastic_StartDay"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>

                  <el-row type="flex" :gutter="12">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col :span="5">{{
                          $t("plasticulturaltab.to")
                        }}</el-col>
                        <el-col :span="4">{{
                          $t("croppingtabs.month")
                        }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Plastic_EndMonth"
                        /></el-col>
                        <el-col :span="4">{{ $t("croppingtabs.day") }}</el-col>
                        <el-col :span="5">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Plastic_EndDay"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{
                            $t("plasticulturaltab.covered_fraction")
                          }}</el-col
                        >
                        <el-col :span="12">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.FilmCoverFraction"
                            :min="0"
                            :max="1"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item v-if="content_use.Plastic_Method == 1">
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("plasticulturaltab.surface") }}</el-col
                        >
                        <el-col :span="12">
                          <el-select
                            v-model="content_use.Surface_Feature"
                            :placeholder="$t('buttons.select')"
                            size="mini"
                            style="width: 100%"
                          >
                            <el-option
                              size="mini"
                              label="flat surface"
                              :value="1"
                            />
                            <el-option
                              size="mini"
                              label="ridge and furrow"
                              :value="2"
                            />
                          </el-select>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    :gutter="8"
                    v-if="content_use.Surface_Feature == 2"
                  >
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("plasticulturaltab.furrow_width") }}</el-col
                        >
                        <el-col :span="12">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Furrow_Width"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    :gutter="8"
                    v-if="content_use.Surface_Feature == 2"
                  >
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("plasticulturaltab.ridge_height") }}</el-col
                        >
                        <el-col :span="12">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Ridge_Height"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{
                            $t("plasticulturaltab.plastic_mulching_end_day")
                          }}</el-col
                        >
                        <el-col :span="12">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Plastic_EndDOY"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row
                    type="flex"
                    :gutter="8"
                    v-if="content_use.Surface_Feature == 2"
                  >
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("plasticulturaltab.ridge_width") }}</el-col
                        >
                        <el-col :span="12">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Ridge_Width"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("plasticulturaltab.cover_ridge") }}</el-col
                        >
                        <el-col :span="12">
                          <el-select
                            v-model="content_use.Ridge_Cover"
                            :placeholder="$t('buttons.select')"
                            size="mini"
                            style="width: 100%"
                          >
                            <el-option size="mini" label="True" :value="1" />
                            <el-option size="mini" label="False" :value="0" />
                          </el-select>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{
                            $t("plasticulturaltab.plastic_ventilation")
                          }}</el-col
                        >
                        <el-col :span="12">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Plastic_Ventilation"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{
                            $t("plasticulturaltab.plastic_mulching_start_day")
                          }}</el-col
                        >
                        <el-col :span="12">
                          <el-input-number
                            size="mini"
                            :controls="false"
                            v-model="content_use.Plastic_StartDOY"
                        /></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("plasticulturaltab.plastic_type") }}</el-col
                        >
                        <el-col :span="12">
                          <el-select
                            v-model="content_use.Plastic_Type"
                            :placeholder="$t('buttons.select')"
                            size="mini"
                            style="width: 100%"
                          >
                            <el-option
                              size="mini"
                              label="Transparent PE"
                              :value="1"
                            />
                            <el-option
                              size="mini"
                              label="White PE"
                              :value="2"
                            />
                            <el-option
                              size="mini"
                              label="Black PE"
                              :value="3"
                            />
                          </el-select>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <el-row type="flex" :gutter="8">
                    <el-col :span="24">
                      <el-row type="flex" :gutter="8">
                        <el-col
                          :span="12"
                          style="
                            padding-left: 6px;
                            padding-right: 6px;
                            display: flex;
                            align-items: center;
                            line-height: 1rem;
                          "
                          >{{ $t("plasticulturaltab.cover_furrow") }}</el-col
                        >
                        <el-col :span="12">
                          <el-select
                            v-model="content_use.Furrow_Cover"
                            :placeholder="$t('buttons.select')"
                            size="mini"
                            style="width: 100%"
                          >
                            <el-option size="mini" label="True" :value="1" />
                            <el-option size="mini" label="False" :value="0" />
                          </el-select>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row
              type="flex"
              :gutter="0"
              style="justify-content: flex-end; margin-top: 1rem"
            >
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="modifyRow(content_use, current_content_list_id)"
                  :disabled="current_content_list_id === null"
                  >{{ $t("croppingtabs.confirm_update") }} {{ tab_name }}
                  {{
                    current_content_list_id !== null
                      ? "(ID=" + content_use[tab_col_id] + ")"
                      : ""
                  }}</el-button
                >
                <el-button size="mini" type="info" @click="addRow(content_use)"
                  >{{ $t("croppingtabs.template_create_new") }}
                  {{ tab_name }}</el-button
                >
              </el-button-group>
            </el-row>
            {{ content_use }}
            <el-divider></el-divider>

            <el-row type="flex" max-height="150">
              <el-table
                :data="content_ex"
                style="width: 100%"
                id="content-list"
              >
                <el-table-column prop="id" label="ID" fixed="left">
                </el-table-column>
                <el-table-column :prop="tab_col_id" :label="'Plastic ID'">
                </el-table-column>

                <el-table-column prop="Cut_Month" label="Month" />
                <el-table-column prop="Cut_Day" label="Day" />
                <el-table-column prop="Cut_Fraction" label="Fraction" />
                <el-table-column
                  :label="$t('buttons.operation')"
                  fixed="right"
                  width="200"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="info"
                      @click="selectRow(scope.row.id)"
                      >{{ $t("buttons.change") }}</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="deleteRow(scope.row.id)"
                      >{{ $t("buttons.delete") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import cropping_base from "@/mixins/cropping_base";
import config_template from "@/data/form_config_template";

export default {
  name: "plasticulturalTab",
  components: {},
  mixins: [cropping_base],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tab_name: "Plasticultural",
      tab_col_id: "Plastic_ID",
      tab_number_key: "Plasticultural_Number",
      tab_content_key: "Plasticultural_Management",
      imported: false,
      current_content_list_id: null,
      config_list: [],
      content_template: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0]
            .Plasticultural.Plasticultural_Management[0]
        )
      ),
      content_use: JSON.parse(
        JSON.stringify(
          config_template.Model_Config.Management.Rotation[0].Years[0]
            .Plasticultural.Plasticultural_Management[0]
        )
      ),
    };
  },
  mounted() {
    // let vm = this;
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "../block_styles";
#content-list {
  td {
    padding: 0.5rem 0;
  }
}
</style>
