import {
    mapGetters
} from "vuex";

export default {

    computed: {
        ...mapGetters(["headers4reqs"]),
        content_ex() {
            let cex = [];
            for (let i = 0; i < this.value[this.tab_content_key].length; i++) {
                let ct = JSON.parse(
                    JSON.stringify(this.value[this.tab_content_key][i])
                );
                ct.id = i;
                cex.push(ct);
            }
            return cex;
        },
        infos_len() {
            return this.value[this.tab_content_key];
        },

    },
    methods: {
        addRow(c) {
            let nc = JSON.parse(JSON.stringify(c));
            nc[this.tab_col_id] =
                this.value[this.tab_content_key][
                    this.value[this.tab_content_key].length - 1
                ][this.tab_col_id] + 1;
            this.value[this.tab_content_key].push(nc);
            this.current_content_list_id = null;
        },
        modifyRow(c, id) {
            this.$set(
                this.value[this.tab_content_key],
                id,
                JSON.parse(JSON.stringify(c))
            );
            // this.value[this.tab_content_key][id] = JSON.parse(JSON.stringify(c));
        },
        deleteRow(id) {
            // this.current_content_list_id = null;
            console.log("del ", id);
            // this.value[this.tab_content_key] =
            this.value[this.tab_content_key].splice(id, 1);
        },
        selectRow(id) {
            this.current_content_list_id = id;
            this.content_use = JSON.parse(
                JSON.stringify(this.value[this.tab_content_key][id])
            );
        },
    },
    watch: {
        infos_len(nv) {
            this.value[this.tab_number_key] = nv.length;
            this.value[this.tab_content_key] = nv;
            this.$emit("input", this.value);
        },
    },
};