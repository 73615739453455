<template>
  <el-form ref="form" :model="value" label-width="150px" labelPosition="top">
    <el-row type="flex" :gutter="12">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ $t("block.cropping") }}</p>
          <div class="form-block-body">
            <el-row type="flex" :gutter="22" style="margin-top: 1rem">
              <el-col :span="24">
                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="18" class="label-text">
                    {{ $t("croppingblock.site_data_simulated_years") }}</el-col
                  >
                  <el-col :span="6">
                    <el-input-number
                      label-width="200px"
                      v-model="site_data_simulated_years"
                      :controls="false"
                      disabled
                      size="mini"
                    />
                  </el-col>
                </el-row>
                <el-row type="flex" :gutter="6" style="line-height: 40px">
                  <el-col :span="18" class="label-text">
                    {{ $t("croppingblock.rotation_number") }}
                  </el-col>
                  <el-col :span="6">
                    <el-input-number
                      label-width="200px"
                      v-model="value.Rotation_Number"
                      :controls="false"
                      size="mini"
                      :min="1"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row type="flex" :gutter="12" style="margin-top: 1rem">
      <el-col :span="24">
        <div class="form-block-unit">
          <p class="form-block-head">{{ $t("block.data") }}</p>
          <div class="form-block-body">
            <el-row
              type="flex"
              :gutter="6"
              style="justify-content: space-between; margin-bottom: 1rem"
            >
              <el-button
                icon="el-icon-caret-left"
                size="mini"
                :disabled="current_rotation_index === 0"
                @click="goPrevRotation"
              ></el-button>
              <div>
                {{ $t("croppingblock.system") }} #
                {{ current_rotation_index + 1 }}
              </div>
              <el-button
                icon="el-icon-caret-right"
                size="mini"
                :disabled="current_rotation_index + 1 === value.Rotation_Number"
                @click="goNextRotation"
              ></el-button>
            </el-row>

            <el-row type="flex" :gutter="6" style="line-height: 40px">
              <el-col :span="12" class="label-text">
                {{ $t("croppingblock.years_last_for") }}
              </el-col>
              <el-col :span="12">
                <el-input-number
                  label-width="200px"
                  v-model="
                    value.Rotation[current_rotation_index].Years_Of_This_Cycle
                  "
                  :controls="false"
                  size="mini"
                />
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="6" style="line-height: 40px">
              <el-col :span="12" class="label-text">
                {{ $t("croppingblock.year_of_a_circle") }}
                {{ value.Rotation[current_rotation_index].Years_of_a_cycle }}
              </el-col>
              <el-col :span="12">
                <el-input-number
                  label-width="200px"
                  v-model="
                    value.Rotation[current_rotation_index].Years_of_a_cycle
                  "
                  :controls="false"
                  size="mini"
                  :change="yearsOfACycleChange"
                  :max="
                    value.Rotation[current_rotation_index].Years_Of_This_Cycle
                  "
                  :min="1"
                />
              </el-col>
            </el-row>

            <el-row
              type="flex"
              :gutter="6"
              style="justify-content: space-between; margin-top: 1rem"
            >
              <el-button
                icon="el-icon-caret-left"
                size="mini"
                @click="current_year_index -= 1"
                :disabled="current_year_index === 0"
              ></el-button>
              <div>
                {{ $t("croppingblock.year") }} # {{ current_year_index + 1 }}
                {{ $t("croppingblock.in_the_circle") }}
              </div>
              <el-button
                icon="el-icon-caret-right"
                size="mini"
                @click="current_year_index += 1"
                :disabled="
                  current_year_index + 1 ===
                    value.Rotation[current_rotation_index].Years_of_a_cycle
                "
              ></el-button>
            </el-row>

            <el-row
              type="flex"
              :gutter="6"
              style="line-height: 40px; margin-top: 1rem"
            >
              <el-col :span="24">
                <el-button
                  style="width: 100%"
                  size="mini"
                  @click="croppting_tabs_visible = true"
                  >{{ $t("croppingblock.define_practices") }} ({{
                    current_year_index + 1
                  }})</el-button
                >
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row class="one-line-complex-form" type="flex" :gutter="6">
              <el-col
                :span="24"
                style="display: flex; justify-content: flex-end"
              >
                <el-button
                  style="width: 100px"
                  @click="$emit('go-next')"
                  type="primary"
                  >{{ $t("buttons.next") }}</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="Farming Management Practices"
      :visible.sync="croppting_tabs_visible"
      width="80%"
    >
      <FarmingPractices
        v-model="
          value.Rotation[current_rotation_index].Years[current_year_index]
        "
      />
    </el-dialog>
  </el-form>
</template>
<script>
import config_template from "@/data/form_config_template";
import FarmingPractices from "./CroppingTabs/FarmingPractices";
export default {
  name: "CroppingFormBlock",
  components: {
    FarmingPractices,
  },
  props: {
    site_data_simulated_years: {
      type: Number,
    },

    value: {
      type: Object,
      default() {
        return config_template.Model_Config.Management;
      },
    },
  },
  data() {
    return {
      croppting_tabs_visible: false,

      current_rotation_index: 0,
      current_year_index: 0,
      form: {},
      rotation_template: config_template.Model_Config.Management.Rotation[0],
    };
  },
  computed: {
    current_Years_of_a_cycle() {
      return this.value.Rotation[this.current_rotation_index].Years_of_a_cycle;
    },
    n_rotations() {
      return this.value.Rotation_Number;
    },
    len_rotations() {
      return this.value.Rotation.length;
    },
  },
  methods: {
    yearsOfACycleChange(currentValue, oldValue) {
      return currentValue, oldValue;
    },
    goNextRotation() {
      this.current_rotation_index += 1;
      this.current_year_index = 0;
    },
    goPrevRotation() {
      this.current_rotation_index -= 1;
      this.current_year_index = 0;
    },
  },
  watch: {
    current_Years_of_a_cycle(nv, ov) {
      console.log("new v:", nv, ";old v: ", ov);
      // 1. 如果该值无法被 Years_Of_This_Cycle 整除的话，就提出警告。
      // 2.监控指定的数组长度，如果years的实际长度超过了这个值，就只保留到这个值的所有元素.
      // 如果 years 的实际长度没有这么长，就用years里的最后一个元素持续填充直到和years的长度和该值一样。
      if (
        nv >
          this.value.Rotation[this.current_rotation_index]
            .Years_Of_This_Cycle ||
        this.value.Rotation[this.current_rotation_index].Years_Of_This_Cycle %
          nv !==
          0
      ) {
        this.$message.error(this.$t("croppingtabs.year_value_error"));
        // this.value.Rotation[this.current_rotation_index].Years_of_a_cycle = ov;
        return ov;
      } else {
        this.value.Rotation[
          this.current_rotation_index
        ].Years = this.value.Rotation[this.current_rotation_index].Years.slice(
          0,
          nv
        );
        let l_remaining =
          nv - this.value.Rotation[this.current_rotation_index].Years.length;
        if (l_remaining > 0) {
          const c_to_fill = JSON.stringify(
            this.value.Rotation[this.current_rotation_index].Years[
              this.value.Rotation[this.current_rotation_index].Years.length - 1
            ]
          );
          for (let i = 0; i < l_remaining; i++) {
            this.value.Rotation[this.current_rotation_index].Years.push(
              JSON.parse(c_to_fill)
            );
          }
        }

        return nv;
      }
    },
    n_rotations(nv) {
      this.current_rotation_index = 0;
      this.current_year_index = 0;
      if (nv > this.value.Rotation.length) {
        for (let i = this.value.Rotation.length; i < nv; i++) {
          let nr = JSON.parse(JSON.stringify(this.rotation_template));
          nr.Rotation_ID = i;
          this.value.Rotation.push(nr);
        }
      } else {
        this.value.Rotation = this.value.Rotation.slice(0, nv);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
@import "@/style/main";
@import "./block_styles";
</style>
